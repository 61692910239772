@import '../../../../styles/vars';

.withdrawMenuContainer {
    position: absolute;
    z-index: 10000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(
            177.43deg,
            rgba(44, 50, 82, 0.24) 0.8%,
            rgba(10, 10, 13, 0.24) 99.2%
        ),
        #09090e;

    width: calc(35% - 2px);
    padding: 24px;
    border-radius: 16px;

    display: flex;
    flex-direction: column;
    gap: 24px;
    border: 1px solid transparent;

    .withdrawBtnsContainer {
        display: flex;
        width: 100%;
        gap: 16px;
        align-items: center;
        height: 52px;

        .withdrawBtn {
            width: 100%;
            height: 100%;
            border: 1px solid transparent;

            border-radius: 16px;

            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 15px;
            letter-spacing: 0.07em;

            color: $color-white;

            &_cancel {
                background: rgba(88, 113, 242, 0.08);
                border-color: rgba(88, 113, 242, 0.42);

                &:hover {
                    background: rgba(88, 113, 242, 0.12);
                    color: rgba(88, 113, 242, 1);

                    border-color: rgba(88, 113, 242, 0.82);
                }
            }

            &_withdraw {
                background: rgba(88, 113, 242, 1);
                border-color: rgba(88, 113, 242, 1);

                &:hover {
                    background: rgba(94, 118, 242, 1);
                    border-color: rgba(168, 181, 248, 1);
                }
            }
        }
    }

    .menuTitle {
        font-family: Inter, sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 35px;
        color: $color-white;
        text-align: center;
    }

    .lightDivider {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > img {
            width: 95%;
            height: 2px;
        }
    }
}

.border {
    position: absolute;

    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    height: 35%;
    border-radius: 16px;
    z-index: -1;
    background: radial-gradient(
        95.12% 137.84% at 50.64% 0%,
        #383c54 0%,
        #13141d 100%
    );
}
