@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.transactionsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 400;
        color: $color-white;
    }
}

.transactionsList {
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    gap: 14px;
    max-height: 23.5vh;
    overflow-y: auto;
    @include custom-scrollbar();
}

.transactionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .transactionHeader {
        display: flex;
        gap: 6px;
        align-items: center;

        .chainImgContainer {
            width: 16px;
            height: 16px;

            display: flex;
            align-items: center;
            justify-content: center;
            background: rgba(19, 23, 43, 1);
            border-radius: 4px;

            & > img {
                width: 12px;
                height: 12px;
            }
        }

        .amountContainer {
            display: flex;
            align-items: flex-end;
            gap: 4px;

            color: $color-white;

            .amountVal {
                font-family: Rubik, sans-serif;
                font-size: 12px;
                font-weight: 500;
                line-height: 13.32px;
            }

            .amountName {
                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 300;
                line-height: 13.32px;
            }
        }

        .linkBtn {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            width: 20px;
            height: 20px;
            background: rgba(26, 26, 40, 1);

            border-radius: 4px;

            & > img {
                width: 12px;
                height: 12px;
            }
        }
    }
}

.transactionFooter {
    display: flex;
    align-items: center;
    gap: 12px;

    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    color: rgba(106, 106, 145, 1);

    .transactionPrice {
        display: flex;
        align-items: center;
        gap: 4px;

        .switchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12px;
            height: 12px;
            border-radius: 2px;
            background: rgba(160, 160, 196, 0.12);
            border: none;
        }
    }

    .transactionTime {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
