@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.notificationBlock {
    width: 100%;
    background-color: #11121D;
    border-radius: 16px;
    padding: 12px 20px;
    gap: 10px;
    @include flexCol(space-between);

    @include mdScreenMedia {
        padding: 0;
        align-self: flex-start;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 0;
        background-color: transparent;
    }
}

.notificationBlockTitle {
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 4px;
}

.notificationBlockContent {
    display: flex;
    align-items: flex-start;
    gap: 12px;
}

.notificationBlockIcon {
    width: 46px;
    height: 46px;
    object-fit: contain;
    margin-top: 3px;
}

.notificationBlockText {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #A0A0C4;
    max-width: 380px;

    @include mdScreenMedia {
        font-size: 11px;
    }
}

.notificationBlockBtn {
    width: 63.1px;
    height: 31.17px;
    padding: 3.04px;
    border-radius: 9.12px;
    background: #0B0B11;
    border: 0.76px solid #303D83;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-left: 70px;
    justify-content: space-between;

    &::after {
        content: 'Off';
        width: 28.51px;
        height: 25.09px;
        border-radius: 7.6px;
        background: #5871F2;
        position: absolute;
        left: 3px;
        transition: $transition;
        color: #000000;
        font-size: 9px;
        font-family: 'Inter', sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_active {
        background: #1A1C2E;
        border: 0.76px solid #5871F2;
        &::after {
            content: 'On';
            left: calc(100% - 31.51px);
            background: #899CFF;
            box-shadow: 1px 1px 7.3px 0px #5871F2A8;
        }
    }
}



.notificationBlockTextContainer {
    @include mdScreenMedia {
        width: 60%;
    }
}

.notificationBlockBtnsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    @include mdScreenMedia {
        width: 30%;
    }
}
