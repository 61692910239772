@import '../../../styles/vars';
@import '../../../styles/mixins';

.widgetBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;
    padding: 16px;

    position: relative;
    border-radius: 16px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.05),
            rgba(255, 255, 255, 0.05)
        ),
        radial-gradient(
            12.43% 9.59% at 0.08% 0.7%,
            rgba(255, 255, 255, 0.15) 0%,
            rgba(21, 21, 30, 0) 100%
        );

    @include gradient-border();

    .blur {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);

        border-radius: 16px;
    }

    .lightDivider {
        width: 100%;
        height: 4px;
    }
}

.subTitle {
    @include setFont(12px, 400, $color-white);
}

.customWalletPicker {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include setFont(14px, 500, $color-white);

    .walletToggleBtn {
        cursor: pointer;
        position: relative;
        width: 48px;
        height: 28px;
        background-color: rgba(11, 11, 17, 1);
        border: 1px solid rgba(48, 61, 131, 1);
        border-radius: 8px;

        &:hover {
            background-color: rgba(18, 18, 28, 1);
            border-color: rgba(57, 73, 157, 1);
        }

        &::after {
            content: '';
            width: 20px;
            height: 20px;
            border-radius: 6px;
            background-color: rgba(137, 156, 255, 1);
            position: absolute;
            left: 4px;
            top: 4px;
            transition: $transition;
        }

        &_active {
            background-color: rgba(38, 38, 59, 1);
            border-color: rgba(137, 156, 255, 1);

            &:hover {
                background-color: rgba(44, 44, 68, 1);
                border-color: rgba(158, 174, 255, 1);
            }

            &::after {
                left: 100%;
                transform: translateX(calc(-100% - 4px));
            }
        }
    }
}

.confirmBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    background: linear-gradient(0deg, #5871f2, #5871f2),
        radial-gradient(
            67.44% 56.7% at 50% -6.7%,
            rgba(251, 251, 251, 0.2) 0%,
            rgba(255, 255, 255, 0) 100%
        );
    background-blend-mode: overlay;

    border: 1px solid rgba(251, 251, 251, 0.8);

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23.04px;
    letter-spacing: 0.07em;
    color: $color-white;

    &:disabled {
        filter: brightness(70%);
        background: linear-gradient(0deg, #5871f2, #5871f2),
            radial-gradient(
                67.44% 56.7% at 50% -6.7%,
                rgba(251, 251, 251, 0.2) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        background-blend-mode: overlay;
    }
}

.hintHighlight {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -16px;
    width: 40%;
    height: 100%;
    background: linear-gradient(
        90deg,
        rgba(137, 156, 255, 0.22) 0%,
        rgba(20, 20, 31, 0) 100%
    );

    @include fade(fadeIn, 0, 1, 0.2s, linear);
    @include fade(fadeOut, 1, 0, 0.2s, linear);

    animation: fadeOut 0.2s linear;

    &_visible {
        opacity: 1;
        animation: fadeIn 0.2s linear;
    }
}

.walletHint {
    top: -30%;
}
