.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(9, 9, 14, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal {
    width: 684px;
    min-height: 254px;
    padding: 24px 0 0;
    border-radius: 16px;
    background: linear-gradient(0deg, #09090E, #09090E),
                linear-gradient(177.43deg, rgba(44, 50, 82, 0.24) 0.8%, rgba(10, 10, 13, 0.24) 99.2%);
    border: 1px solid rgba(255, 255, 255, 0.12);
  }
  
  .modalHeader {
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  
    h3 {
      color: white;
      font-size: 20px;
      margin: 0;
    }
  }
  
  .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 8px;
  }
  
  .modalContent {
    padding: 0 24px;
    margin-bottom: 32px;
  }
  
  .input {
    width: 100%;
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    padding: 12px 16px;
    color: white;
    margin-bottom: 16px;
  
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  .modalFooter {
    padding: 16px 24px;
    display: flex;
    justify-content: flex-end;
    gap: 16px;
    border-top: 1px solid rgba(255, 255, 255, 0.12);
  }
  
  .cancelButton, .saveButton {
    padding: 12px 24px;
    border-radius: 8px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .cancelButton {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.12);
    color: white;
  }
  
  .saveButton {
    background: #2C6FED;
    border: none;
    color: white;
  }
  