@import '../../../styles/vars';
@import '../../../styles/mixins';

.supportBlock {
    padding-top: 35px;

    &__routeTitle {
        @include smScreenMedia {
            margin-bottom: 15px;
        }
    }

    &__input {
        width: 100%;
        background: transparent;
        border: none;
        color: $color-white;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        outline: none;
        
        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }
    
    &__textarea {
        width: 100%;
        min-height: 100px;
        background: transparent;
        border: none;
        color: $color-white;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        outline: none;
        resize: vertical;
        
        &::placeholder {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    &__container {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        padding: 30px;
        
        .supportBlock__main {
            &:first-child {
                width: 627.5px;
                min-height: 292px;
                backdrop-filter: blur(8px);
                -webkit-backdrop-filter: blur(8px);
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
                      radial-gradient(12.43% 9.59% at 0.08% 0.7%, rgba(255, 255, 255, 0.15) 0%, rgba(21, 21, 30, 0) 100%);
                border: 1px solid rgba(255, 255, 255, 0.1);
                padding: 20px;
                border-radius: 20px;
            }
            
            &:last-child {
                width: 627.5px;
                min-height: 271px;
                backdrop-filter: blur(8px);
                -webkit-backdrop-filter: blur(8px);
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
                      radial-gradient(12.43% 9.59% at 0.08% 0.7%, rgba(255, 255, 255, 0.15) 0%, rgba(21, 21, 30, 0) 100%);
                border: 1px solid rgba(255, 255, 255, 0.1);
                padding: 20px;
                border-radius: 20px;
            }
        }
    }

    // &__main {
    //     background-color: $color-black-bold;
    //     border-radius: 16px;
    //     padding: 50px;

    //     @include smScreenMedia {
    //         padding: 15px;
    //     }
    // }

    &__title {
        @include setFont(24px, 600);
        margin-bottom: 10px;
        font-family: 'Gilroy', sans-serif;

        @include smScreenMedia {
            margin-bottom: 10px;
        }
    }

    &__text {
        @include setFont(12px, 400, #b2b0c8);
        margin-bottom: 20px;
        font-family: 'Inter', sans-serif;

        @include smScreenMedia {
            font-size: 11px;
        }
    }

    &__sendbtn {
        width: 100%;
        background: linear-gradient(0deg, #5871F2, #5871F2);        
        border-radius: 16px;
        padding: 12px;
        display: flex;
        align-items: center;
        justify-content: center; 
        cursor: pointer;
        text-decoration: none;
        font-family: 'Inter', sans-serif;
        color: #FFFFFF;
        font-size: 12px;
        border: 0.1px solid #ffffff9e;
    }

    &__btns {
        @include flexCol(stretch, flex-start);
        flex-direction: column;
        gap: 10px;
        text-decoration: none;
    }

    &__btn {
        width: 100%;
        background-color: #131832;
        border-radius: 12px;
        padding: 12px;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        gap: 7px;
        cursor: pointer;
        text-decoration: none;
        transition: background-color 0.3s ease;
    
        &:hover {
            background-color: #273166;
        }
    
        .supportBlock__main:first-child & {
            &:last-child {
                background-color: #131832;
                border: none;
                
                &:hover {
                    background-color: #273166;
                }
            }
        }
    
        .supportBlock__main:last-child & {
            background-color: #131832;
            border: 1px solid rgba(255, 255, 255, 0.1);
            
            &:hover {
                background-color: #273166;
                border-color: rgba(255, 255, 255, 0.2);
            }
            
            &:has(input:focus), &:has(textarea:focus) {
                background-color: #273166;
                border-color: #5871F2;
            }
        }
    }

    &__btnIcon {
        @include svg(24px, $color-white);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__btnText {
        @include setFont(14px, 400);
        font-family: 'Inter', sans-serif;
        color: $color-white;
    }

    &__btnLabel {
        color: #FFFFFF;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
        font-weight: 300;
    }

    &__btnText_link {
        color: $color-white;
    }

    &__modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        display: none;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    
        &_active {
            display: flex;
        }
    }
    
    &__modalContent {
        width: 494px;
        height: 637px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 12px;
        background: 
            url('../../../assets/images/rocket.png') bottom center/contain no-repeat,
            linear-gradient(177.43deg, rgba(44, 50, 82, 0.24) 0.8%, rgba(10, 10, 13, 0.24) 99.2%),
            linear-gradient(0deg, #09090E, #09090E);
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.1);
        padding: 25px;
        text-align: center;
    }
    
    &__modalTitle {
        color: #FFFFFF;
        font-size: 30px;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        margin-bottom: 10px;
    }
    
    &__modalButton {
        width: 100%;
        background: #5871F2;
        color: #FFFFFF;
        padding: 12px 24px;
        border-radius: 12px;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-family: 'Inter', sans-serif;
        margin-top: auto;
    }

    &__modalSubtitle {
        color: #B2B0C8;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 150%;
        text-align: center;
    }
}
