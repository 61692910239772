@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.arbitrageListItem {
    width: 100%;
    padding: 16px 16px;
    background: $color-blue-arb;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;


    
    

    @include smScreenMedia {
        padding: 17px 15px 15px;
    }

    &__header {
        @include flexCol(space-between);
        margin-bottom: 12px;
    }

    &__title {
        font-family: 'Gilroy', sans-serif;
        @include setFont(20px, 700);
    }

    &__bridge {
        @include setFont(16px, 700);
        text-align: center;
        cursor: pointer;
    }

    &__bridgeBlock {
        margin-top: 4px;
        padding: 4px 16px;
        border-radius: 30px;
        text-align: center;
        display: block;
        cursor: pointer;
    }

    &__headerBtns {
        @include flexCol();
        gap: 8px;
    }

    &__hiderBtn {
        cursor: pointer;
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__hideIcon {
        @include svg(28px, $color-grey);
    }

    &__reportIcon {
        @include svg(28px, $color-grey);
    }

    &__crossIcon {
        @include svg(28px, transparent, 28px);
    }

    &__line {
        display: block;
        width: 100%;
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 10px 0;
        position: relative;
    }

    &__resultBlock {
        border: 1px solid #1E7C4C;
        border-radius: 10px;
        padding: 10px 15px 25px 15px;
        @include flexCol(flex-start, flex-start);
        flex-direction: column;
        gap: 5px;

        position: relative;

        &_output {
            border: 1px solid #1E7C4C;            
            background-color:#0F3D25;
            margin-bottom: 7px;
        }

        &_input {
            background-color: #3E0E0F;
            border: 1px solid #841D1F;
            margin-bottom: 15px;
        }
    }

    &__divider {
    color: #212233;
    padding: 0 8px;
    }

    &__dividersec {
        color: #212233;
        padding: 0 3px;
        }
    

    &__profitPercentBlock {
        background: #58F1720F;
        padding: 4px 8px 4px 4px;
        gap: 4.61px;
        border-radius: 7px;
        display: inline-flex;
        align-items: center;
        color: #58F172;
        font-weight: 500;
        margin-left: 4px;
        min-width: 82.61px;
        height: 24px;
        transform: translateY(3px);


        svg {
            min-width: 15px;
            min-height: 15px; 
            flex-shrink: 0;
        }
    }

    &__resultBlockText {
        font-size: 12px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        color: #FFFFFF;
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex; 
        align-items: center;

        &_green {
           color: #58F272;
           min-width: 130px;
           ;
        }

        &_red {
            color: #ff715f;
            min-width: 130px;
        }
    }

    &__resultBlockLinkText {
        color: inherit;

        &:nth-of-type(2) {
            padding-left: 10px;
        }
    }

    &__profitText {
        @include setFont(16px, 400);
        font-family: 'Inter', sans-serif;
        margin-bottom: 10px;
        margin-top: 5px;
        color: #7F7F9D;

        @include smScreenMedia {
            font-size: 15px;
        }

        &_white {
            color: #FFFFFF;
            font-weight: 700;
            font-family: 'Inter', sans-serif;
        }
    }

    &__infoText {
        @include setFont(12px, 700, $color-grey);
        margin-bottom: 10px;

        @include smScreenMedia {
            font-size: 16px;
        }

        &_blue {
            color: $color-main;
        }
    }

    &__footer {
        position: relative;
        margin-top: 15px;
        display: flex;
    }

    &__footerBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 8px;
        margin-left: auto;
    }

    &__footerText {
        @include setFont(12px, 400);
        color: #5B5B7B;
    
        // Add specific styling for links in the footer
        .arbitrageListItem__footer & {
            &[href] {
                font-size: 10px;
            }
        }
    
        @include smScreenMedia {
            font-size: 10px;
        }
    }

    &__infoBlock {
        margin-top: 4px;
        display: flex;
        gap: 8px;
    }

    &__exhcangeBlock {
        position: absolute;
        height: 20px;
        bottom: 10px;
        padding: 4px 15px;
        border-radius: 30px;
        margin-top: 4px;
        display: flex;
        align-items: center;
    }

    &__exhcangeBlockText {
        font-family: 'Inter', sans-serif;
        font-size: 8px;
        font-weight: 600;
    }

    &__purchaseInfoBlock {
        display: flex;
        justify-content: space-between;
    }

    &__footerBtns {
        justify-content: flex-end;
        width: 33%;
        height: 40px;
        flex-direction: column;
        display: flex;
        gap: 6px;
    }

    &__snipeBtn {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        cursor: pointer;
        padding: 10px;
        border-radius: 12px;
        border: 1px solid #5872f2a1;
        background-color: #5871F240; 
        font-family: 'Inter', sans-serif;
        font-size: 18px;
        font-weight: 600;

        @include smScreenMedia {
            border-radius: 5px;
        }
    }


    .arbitrageList {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__outflowsBtn {
        width: 65px;
        height: 20px;
        position: absolute;
        bottom: 10px;
        right: 15px;
        padding: 5px;
        font-family: 'Inter', sans-serif;
        font-size: 8px;
        font-weight: 600;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        background: linear-gradient(90deg, #5871F2, #8b9df7),
                    radial-gradient(50% 190.68% at 100% 50%, rgba(182, 194, 255, 0.42) 0%, rgba(105, 128, 247, 0.0168) 100%);
        
                    

        @include smScreenMedia {
            border-radius: 5px;
        }
    }

    &__outflowsHint {
        background-color: $color-blue-passive;

        padding: 8px;
        border-radius: 10px;

        position: absolute;
        top: 17px;
        left: 2.5%;
        width: 95%;

        display: flex;
        justify-content: space-between;
        align-items: center;

        @include setFont(12px, 600, $color-white);
    }

    &__outflowsHintLink {
        display: flex;
        align-items: center;

        @include setFont(15px, 600, $color-grey);

        @include mdScreenMedia {
            width: 100%;
            padding: 10px 5px;
            justify-content: flex-start;
        }
    }

    &__outflowsHintLinkIcon {
        margin-left: 5px;

        @include svg(16px, $color-grey);

        @include mdScreenMedia {
            margin-left: 3px;
            @include svg(12px, $color-grey);
        }
    }
}
