@import '../../../../styles/mixins';

.optionsBlock {
    display: flex;
    position: sticky;
    top: 18px;
    gap: 8px;
    padding: 0 0 0 30px;

    &__leftButtons {
        display: flex;
        gap: 10px;
    }

    &__rightButtons {
        display: flex;
        gap: 10px;
        margin-left: auto;
        padding: 0 30px 0 0;
    }

    @include smScreenMedia {
        width: 100%;
        top: 10px;
        gap: 5px;
        justify-content: center;
        flex-wrap: wrap;
    }

    &__btn {
        border-radius: 16px;
        background-color: #5871F240;
        font-family: 'Inter', sans-serif;
        font-weight: 400;
        color: #E7E7E8;
        @include flexCol();
        gap: 10px;
        width: 100px; 
        height: 40px;
        cursor: pointer;
        border: 1px solid #5871F26B;
        transition: $transition;
        &[data-refresh="true"] {
            width: 150px;
        }

        &_active {
            background-color: #5871F2 !important;
        }

        @include smScreenMedia {
            padding: 6px 12px;
            width: 45%;
        }
    }

    &__icon {
        @include svg(18px, $color-white, 18px);
    }
}
