@import '../../../styles/mixins';

.adminNews {
    padding-top: 20px;
}

.adminNews__btn {
    margin: 0 auto;
    max-width: 200px;
    width: 100%;
    display: block;
}
