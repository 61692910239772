.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  width: 510px;
  height: 480px;
  padding: 24px 0 0 0;
  border-radius: 16px;
  background: linear-gradient(0deg, #09090E, #09090E),
              linear-gradient(177.43deg, rgba(44, 50, 82, 0.24) 0.8%, rgba(10, 10, 13, 0.24) 99.2%);
  border: 1px solid #2C3252;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  line-height: 44.64px;
  text-align: center;
}

.blockchainSection {
  padding: 0 24px;

  .label {
    font-weight: 500;
    margin-bottom: 16px;
    font-family: 'Inter', sans-serif;
    color: #fff;
    font-size: 12px;
  }

  .blockchainOptions {
    display: flex;
    gap: 7.11px;
    margin: 5px 0;
    
    .option {
      width: 208px;
      height: 38px;
      border-radius: 6.4px;
      background: #13141F;
      display: flex;
      cursor: pointer;
      transition: all 0.3s ease;
      align-items: center;
      justify-content: center;
      border: 1px solid transparent;

      &:hover {
        border: 1px solid #25DAB2;
        box-shadow: 0px 4px 9.4px -1px #25DAB240;
      }

      &.active {
        border: 1px solid #25DAB2;
        box-shadow: 0px 4px 9.4px -1px #25DAB240;
      }

      img {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.inputGroup {
  padding: 0 24px;
  
  .label {
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    color: #fff;
    font-size: 12px;
    margin-bottom: 12px;
  }
}

.inputContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.inputIcon {
  position: absolute;
  left: 16px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.input {
  width: 100%;
  height: 46px;
  padding: 12px 12px 12px 64px;
  background: #131832;
  border: 1px solid rgba(88, 113, 242, 0.42);
  border-radius: 12px;
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 300;

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  
  &:focus {
    outline: none;
    border-color: rgba(88, 113, 242, 0.8);
  }
}

.buttonContainer {
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 5px;
  padding-bottom: 24px;

  .primaryButton {
    width: 100%;
    height: 42px;
    padding: 0 16px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 16px;
    background: #5871F2;
    border: none;
    color: white;
    cursor: pointer;
    
    &:hover {
      opacity: 0.9;
    }
  }

  .secondaryButton {
    width: 100%;
    height: 42px;
    padding: 0 16px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    border-radius: 16px;
    background: rgba(88, 113, 242, 0.08);
    border: 1px solid rgba(88, 113, 242, 0.42);
    color: #5871F2;
    cursor: pointer;
    
    &:hover {
      background: rgba(88, 113, 242, 0.12);
    }
  }
}
