@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.settingsForm {
    &__input {
        margin-bottom: 10px;
        &_repeat {
            margin-bottom: 0;
        }
    }

    &__fieldTitle {
        margin-bottom: 8px;
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
    }

    &__line {
        width: 100%;
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 20px 0;
        position: relative;
        display: block;
    }

    &__btn {
        @include smScreenMedia {
            margin-top: 18px;
        }
        &_primary {
            background-color: #272f58f9;
            border: 1px solid #5872f272;
            color: white;
        }
    }

    &__notText {
        @include setFont(14px, 400, $color-white);
        padding: 0;
        transition: $transition;
        max-height: 0px;
        text-align: center;
        opacity: 0;

        &_fail {
            color: $color-red;
        }

        &_success {
            color: #75ffac;
        }

        &_active {
            opacity: 1;
            padding: 10px 0;
            max-height: 50px;
        }
    }

    &__readOnlyField {
        background-color:#131832;
        border-radius: 10px;
        padding: 16px;
        @include setFont(16px, 400);
        border: none;
        outline: none;

        display: flex;
        gap: 16px;
        align-items: center;

        & > svg {
            @include svg(16px, transparent, 16px, $color-white);
        }
        & > span {
            padding: 6px 0;
        }
    }
}
