@import '../../../styles/vars';
@import '../../../styles/mixins';

.arbitrageList {
    padding-top: 20px;

    &__container {
        height: 80vh;
        border-radius: 16px;
        padding: 20px;


        @include mdScreenMedia {
            padding: 0;
            background-color: transparent;
        }
    }
}

.grid {
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        margin-left: 5px;
        border-radius: 20px;
        border: 5px solid $color-main;
    }
}
