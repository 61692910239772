@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.modalButtons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include smScreenMedia {
        margin-top: 30px;
    }
}
