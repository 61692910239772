@import '../../../styles/vars';
@import '../../../styles/mixins';

.newsItemModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 800px;
    width: calc(100% - 20px);
    background: radial-gradient(
            circle at -10% 50%,
            rgba(88, 113, 242, 0.2) 2%,
            rgba(88, 113, 242, 0) 37%
        ),
        radial-gradient(
            circle at 110% 50%,
            rgba(88, 113, 242, 0.2) 2%,
            rgba(88, 113, 242, 0) 37%
        ),
        $color-blue-bold;
    border-radius: 20px;
    @include flexCol();
    padding: 20px;
    z-index: 99999999;
    gap: 20px;
    flex-direction: column;
    max-height: calc(100vh - 20px);
    overflow: hidden;

    @include smScreenMedia {
        gap: 10px;
    }

    &__imageBlock {
        flex: 4;
        max-width: 60%;

        @include smScreenMedia {
            max-width: 100%;
        }
    }

    &__contentBlock {
        height: 100%;
        overflow-y: auto;
        @include flexCol();
        flex-direction: column;
        gap: 20px;
    }

    &__img {
        width: 100%;
        aspect-ratio: 1.5/1;
        border-radius: 13px;
        object-fit: cover;
    }

    &__textBlock {
        flex: 6;
        width: 100%;
    }

    &__title {
        width: 100%;
        @include setFont(25px, 700);
        margin-bottom: 20px;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 0.5px solid $color-grey;

        @include smScreenMedia {
            font-size: 18px;
        }
    }

    &__text {
        text-align: center;
    }
}
