@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.filterModal {
    width: 100%;
    max-width: 347px;
    background-color: $color-blue-bold;
    padding: 40px 20px;
    border-radius: 20px;
    position: fixed;
    margin: 20px 10px 0 0;
    right: 0;
    top: 0;
    height: 100%;
    overflow-y: hidden;
    z-index: 99999999;

    @include smScreenMedia {
        max-width: calc(100% - 32px);
        height: calc(100% - 80px);
        top: 50%;
        transform: translate(calc(0% - 16px), -50%);
        padding: 16px 16px 20px;
        border-radius: 20px;
    }

    &__crossBtn {
        display: none;
        @include mdScreenMedia {
            display: block;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
        padding-left: 2px;
        padding-right: 6px;
        scrollbar-width: none;
        -ms-overflow-style: none;
        height: 100%;

        &::-webkit-scrollbar {
            display: none;
        }

        @include smScreenMedia {
            height: calc(100% - 30px);
            margin-top: 30px;
        }
    }
}

.filterLabel {
    font-size: 16px;
    color: #fff;
    margin-bottom: 8px;
    font-weight: 700;
    text-align: center;
}

.sideBarLinks__linebtm {
    width: 100%;
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 20px 0;
        position: relative;
        display: block;
}

.sortToggle {
    width: 299px;
    height: 48px;
    background: #0B0B11;
    border: 1px solid #303D83;
    border-radius: 12px;
    padding: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    
    &__button {
        width: 145.5px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: transparent;
        border: none;
        color: #5871F2;  // Цвет текста по умолчанию
        font-size: 14px;
        border-radius: 6px;
        transition: all 0.3s ease;
        
        &--active {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13)),
                        linear-gradient(0deg, #273166, #273166),
                        radial-gradient(63.23% 295.45% at 0% 50%, rgba(137, 156, 255, 0.4) 0%, rgba(22, 28, 59, 0) 100%);
            color: #fff;
        }
    }
}
