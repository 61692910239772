@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.wrapper {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: radial-gradient(
        50.03% 52.48% at 50.03% 47.52%,
        rgba(17, 17, 17, 0.6232) 0%,
        rgba(0, 0, 0, 0.82) 100%
    );
    top: 0;
    left: 0;

    .menuContainer {
        position: absolute;
        width: 35%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 100;
        padding: 24px;
        background: linear-gradient(
            177.43deg,
            rgb(20, 22, 34) 0.8%,
            rgba(10, 10, 13, 1) 99.2%
        );

        border-radius: 12px;
        border: 1px solid rgba(88, 113, 242, 0.42);

        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .tokensList {
            display: flex;
            flex-direction: column;
            gap: 24px;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            max-height: 30vh;
            padding-right: 12px;

            @include custom-scrollbar();
        }

        .lightDivider {
            width: 100%;
            height: 4px;
        }

        .searchInput {
            padding: 8px 16px;
            background: rgba(19, 24, 50, 1);
            border-radius: 12px;
            border: 1px solid rgba(88, 113, 242, 0.42);

            &_focus {
                background: radial-gradient(
                        14.11% 89.93% at 0% 50%,
                        rgba(137, 156, 255, 0.26) 0%,
                        rgba(21, 21, 30, 0) 100%
                    ),
                    #131832;
            }
        }
    }
}

.tokenItem {
    display: grid;
    gap: 2%;
    grid-template-columns: 36px 63% auto;

    .tokenExtraData {
        display: flex;
        width: 100%;

        flex-direction: column;
        justify-content: space-between;

        .tokenInfo {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 4px;

            .tokenInfoItem {
                display: flex;
                border-radius: 4px;
                padding: 2px 6px;
                background: rgba(30, 33, 53, 1);

                font-family: Inter, sans-serif;
                font-size: 8px;
                font-weight: 400;
                line-height: 11.52px;
                letter-spacing: 0.07em;

                .tokenInfoTitle {
                    color: rgba(160, 160, 196, 1);
                }

                .tokenInfoValue {
                    color: rgba(137, 156, 255, 1);
                }
            }
        }

        .tokenName {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
            color: $color-white;

            display: flex;
            align-items: center;
            gap: 6px;

            .nameDivider {
                color: rgba(160, 160, 196, 1);
            }
        }
    }

    .tokenLabels {
        display: flex;
        width: 100%;

        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;

        .tokenPrice {
            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 14.52px;
            color: $color-white;
        }

        .tokenAddress {
            display: flex;
            width: 100%;
            justify-content: space-between;

            font-family: Inter, sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 12.1px;
            letter-spacing: 0.07em;

            .tokenAddressTitle {
                color: rgba(160, 160, 196, 1);
            }

            .tokenAddressValue {
                color: rgba(137, 156, 255, 1);
            }

            .tokenAddressBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                background: rgba(137, 156, 255, 0.14);
                border: none;
            }
        }
    }

    .tokenImgContainer {
        width: 36px;
        height: 36px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(34, 38, 62, 1);

        & > img {
            width: 80%;
            height: 80%;
            border-radius: 4px;
        }
    }
}
