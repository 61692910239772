@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.trackerListItem {
    width: 100%;
    padding: 16px 16px;
    background: $color-blue-arb;
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(255, 255, 255, 0.3);
    border-radius: 16px;

    @include smScreenMedia {
        padding: 17px 15px 15px;
    }

    &__header {
        @include flexCol(space-between);
        margin-bottom: 10px;
    }

    &__title {
        font-family: 'Gilroy', sans-serif;
        @include setFont(20px, 700);
    }

    &__headerBtns {
        @include flexCol();
        gap: 8px;
    }

    &__hiderBtn {
        cursor: pointer;
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__gridContainer {
        display: grid;
        grid-template-columns: repeat(3, 90.78px);
        grid-template-rows: repeat(2, 29px); 
        gap: 6px;
        justify-content: center;
        width: 100%;
    }
    
    &__gridItem {
        width: 90.78px;
        height: 29px;
        font-family: 'Inter', sans-serif;
        font-size: 12px;
        display: flex;
        align-items: center;
        gap: 6px;
        color: #FFFFFF;
        font-weight: 400;
        padding: 4px 8px;
        border-radius: 6px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13)),
                    linear-gradient(0deg, #273166, #273166);
                    &_active {
                        background: #5871F2;
                        transition: opacity 0.2s;
                        border: 1px solid #ffffff;
                        cursor: pointer;
                
                        &:hover {
                            opacity: 0.8;
                        }
    
        }
    }

    &__exchangeIcon {
        width: 16px;
        height: 16px;
        flex-shrink: 0;
    }

    &__hideIcon {
        @include svg(28px, $color-grey);
    }
    
    &__crossIcon {
        @include svg(28px, $color-grey);
    }
    
    &__reportIcon {
        @include svg(28px, $color-grey);
    }
    &__iconWrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }
    

    &__resultBlock {
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        padding: 10px 15px;
        @include flexCol(flex-start, flex-start);
        flex-direction: column;
        gap: 2px;

        &_up {
            border-color: #5469CF;
            background-color: #080912;;
            margin-bottom: 15px;
        }

        &_fall {
            background-color:#080912;
            border-color: #5469CF;
            margin-bottom: 15px;
        }
    }

    &__resultBlockText {
        display: grid;
        grid-template-columns: 140px 1fr;
        align-items: center;
        font-size: 12px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        color: #A0A0C4;
        white-space: nowrap;
        width: 100%;
        min-height: 20px;

        &:has(span:contains('Current price:'), 
              span:contains('Network:'), 
              span:contains('Token contract:')) > *:last-child {
            color: #FFFFFF;
        }
    }

    &__resultBlockValue {
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
    }

    &__gridTitle {
        font-size: 12px;
        font-weight: 400;
        font-family: 'Inter', sans-serif;
        color: #A0A0C4;
        margin-bottom: 10px;
        text-align: left;
    }

    &__resultBlockIcon {
        display: flex;
        width: 116px;
        height: 25px;
        margin-left: -46px;
    }

    

    &__resultBlockLinkText {
        color: inherit;
    }

    &__profitText {
        @include setFont(17px, 700);
        margin-bottom: 5px;
        margin-top: 5px;

        @include smScreenMedia {
            font-size: 15px;
        }

        &_blue {
            color: $color-main;
        }
    }

    &__infoText {
        @include setFont(12px, 700, $color-grey);
        margin-bottom: 10px;

        @include smScreenMedia {
            font-size: 15px;
        }

        &_blue {
            color: $color-main;
        }
    }

    &__footer {
        @include flexCol(flex-end);
        margin-top: 10px;

        @include smScreenMedia {
            margin-top: 20px;
        }
    }

    &__footerBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 10px;
        margin-left: auto;
    }
    
    &__footerBtns {
        justify-content: flex-end;
        width: 35%;
        flex-direction: column;
        display: flex;
        gap: 6px;
    }
    
    &__dividersec {
        color: #212233;
        padding: 0 2px;
    }

    &__snipeBtn {
        width: 30%;
        padding: 6px;
        margin-right: auto;

        @include setFont(15px, 600);
    }

    &__footerText {
        @include setFont(12px, 400, $color-grey);

        @include smScreenMedia {
            font-size: 10px;
        }
    }

    &__footerTextbtm {
        font-family: 'Gilroy', sans-serif;
        font-weight: 500;
        font-size: 10px;
        color: #C1CAFC;
        cursor: pointer;
        text-decoration: none;
        
        &:hover {
            text-decoration: underline;
            opacity: 0.8;
        }
    }

    &__exhcangeBlock {
        margin-top: 4px;
        padding: 4px 16px;
        border-radius: 30px;
    }

    &__exhcangeBlockText {
        @include setFont(14px, 400);
    }
}
