// medias

$sm-screen: 576px;
$md-screen: 768px;
$lg-screen: 1024px;
$xl-screen: 1282px;

// colors
$color-bg: #0a0c1a;
$color-white: #fff;
$color-bg-transparent: rgba(0, 0, 0, 0.58);
$color-black: #070911;
$color-black-bold: #06070d;
$color-dark-grey: linear-gradient(0deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)),
linear-gradient(177.43deg, rgba(44, 50, 82, 0.47) 0.8%, rgba(10, 10, 13, 0.47) 99.2%),
radial-gradient(83.31% 12.83% at 50% 0%, rgba(127, 147, 255, 0.36) 0%, rgba(21, 21, 30, 0) 100%),
radial-gradient(73.97% 7.66% at 52.59% 101.42%, rgba(127, 147, 255, 0.13) 0%, rgba(21, 21, 30, 0) 100%),
radial-gradient(67.54% 2.66% at 50% 100%, rgba(137, 156, 255, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
$color-input-bg: #0a0d18;
$color-blue-bold: #0d1124;
$color-blue-arb: linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
radial-gradient(12.43% 9.59% at 0.08% 0.7%, rgba(255, 255, 255, 0.15) 0%, rgba(21, 21, 30, 0) 100%);
$color-blue-background: linear-gradient(0deg, #09090E, #09090E),
    linear-gradient(177.43deg, rgba(44, 50, 82, 0.24) 0.8%, rgba(10, 10, 13, 0.24) 99.2%),
    radial-gradient(67.54% 2.66% at 50% 100%, rgba(137, 156, 255, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
$color-blue-bg: #080a16;
$color-blue-active: #2f3c99;
$color-blue-passive: #273166;
$color-main: #5871f2;
$color-red: #ff4040;
$color-red-bold: #2f0f1f;
$color-green: #144231;
$color-light-green: #75ffac;
$color-grey: #a5a5bb;
$color-border: #1f2336;
$color-text: #8f9ccb;
$slidebar-color: rgb(248, 36, 8);

$container-padding: 16px;


$gradient-default: linear-gradient(90deg, rgba(137, 156, 255, 0.12) 0%, rgba(49, 81, 251, 0.02) 100%),
radial-gradient(32.25% 112.5% at 0% 50%, rgba(137, 156, 255, 0.0346667) 0%, rgba(21, 21, 30, 0) 100%);
$border-gradient: radial-gradient(68.57% 7100% at 50% 0%, #606060 0%, #282727 100%);

$font-fam-def: 'Inter', sans-serif;
$transition: all 0.5s;
