@import '../../../styles/vars';
@import '../../../styles/mixins';

.subscribtionContainer {
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    min-height: 95vh;
    padding-bottom: 35px;

    &__title {
        margin-bottom: 35px;
    }
}
