@import '../../../styles/vars';
@import '../../../styles/mixins';

.signin {
    margin-top: 172px;

    @include lgScreenMedia {
        margin-top: 140px;
    }

    @include mdScreenMedia {
        margin-top: 100px;
    }

    @include smScreenMedia {
        margin-top: 80px;
    }

    &__container {
        @include flexCol();
        flex-direction: column;
    }

    &__title {
        margin-bottom: 15px;

        @include smScreenMedia {
            margin-bottom: 20px;
        }
    }

    &__text {
        text-align: center;
        margin-bottom: 66px;

        @include mdScreenMedia {
            margin-bottom: 45px;
        }

        @include smScreenMedia {
            margin-bottom: 35px;
        }
    }
}
