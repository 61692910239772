.chartContainer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    padding: 16px;
    border: 1px solid rgba(36, 36, 57, 1);
    background: linear-gradient(
        180deg,
        rgba(44, 50, 82, 0.24),
        rgba(10, 10, 13, 0.24)
    );

    width: 100%;
    height: 100%;

    .title {
        font-family: Inter, sans-serif;
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        color: rgba(160, 160, 196, 1);
    }
}

.chartWrapper {
    overflow: hidden;
    width: 100%;
    height: 100%;
    border-radius: 12px;

    .errorMsg {
        font-family: Inter, sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 20px;
        color: rgba(160, 160, 196, 1);
    }
}

.chartFrame {
    width: 100%;
    height: calc(100% + 40px);
    border: none;
}
