@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.fundingListItem {
    width: 100%;
    padding: 25px 30px 20px;
    background-color: $color-blue-bold;
    border-radius: 10px;
    height: 100%;

    @include smScreenMedia {
        padding: 17px 15px 15px;
    }

    &__header {
        @include flexCol(space-between);
        margin-bottom: 25px;
    }

    &__title {
        @include setFont(16px, 700);
    }

    &__headerBtns {
        @include flexCol();
        gap: 8px;
    }

    &__hiderBtn {
        cursor: pointer;
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__hideIcon {
        @include svg(22px, $color-grey);
    }

    &__reportIcon {
        @include svg(18px, $color-grey);
    }

    &__crossIcon {
        @include svg(12px, transparent, 12px, $color-grey);
    }

    &__resultBlock {
        border-width: 1px;
        border-style: solid;
        border-radius: 10px;
        padding: 17px 15px;
        @include flexCol(flex-start, flex-start);
        flex-direction: column;
        gap: 5px;

        &_output {
            border-color: rgba(69, 95, 78, 0.45);
            background-color: $color-green;
            margin-bottom: 7px;
        }

        &_input {
            background-color: $color-red-bold;
            border-color: rgba(95, 69, 69, 0.45);
            margin-bottom: 15px;
        }
    }

    &__resultBlockText {
        @include setFont(13px, 400);
        white-space: nowrap;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;

        &_green {
            color: $color-light-green;
        }

        &_red {
            color: #ff715f;
        }
    }

    &__resultBlockLinkText {
        color: inherit;
    }

    &__profitText {
        @include setFont(17px, 700);
        margin-bottom: 5px;
        margin-top: 5px;

        @include smScreenMedia {
            font-size: 15px;
        }

        &_blue {
            color: $color-main;
        }
    }
}
