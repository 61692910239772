@import '../../../styles/vars';
@import '../../../styles/mixins';

.arbitrageNews {
    margin-bottom: 224px;

    @include lgScreenMedia {
        margin-bottom: 160px;
    }

    @include mdScreenMedia {
        margin-bottom: 120px;
    }

    @include lgScreenMedia {
        margin-bottom: 100px;
    }

    &__title {
        margin-bottom: 15px;

        @include mdScreenMedia {
            margin-bottom: 20px;
        }
    }

    &__subText {
        text-align: center;
        margin-bottom: 74px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;

        @include mdScreenMedia {
            margin-bottom: 47px;
        }

        @include smScreenMedia {
            margin-bottom: 20px;
        }
    }

    &__topBlock {
        @include flexCol();
        gap: 36px;
        margin-bottom: 40px;

        @include xlScreenMedia {
            flex-direction: column;
        }

        @include smScreenMedia {
            gap: 20px;
            margin-bottom: 30px;
        }
    }

    &__topBlockImages {
        @include flexCol(center, flex-end);
        gap: 12px;
        padding: 24px 24px 0;
        background-image: url('../../../assets/images/MainPage/newsTopBlockBg.jpg');
        flex: 6;

        @include mdScreenMedia {
            flex-direction: column;
            gap: 15px;
            padding: 18px 20px;
        }
    }

    &__blurBox {
        border-radius: 17px;
        background-position: center;
        background-size: cover;
        position: relative;
    }

    &__topBlockImage {
        border-radius: 13px 13px 0 0;
        overflow: hidden;

        &:first-child {
            width: 45%;
        }

        &:nth-child(2) {
            width: 55%;
        }

        @include mdScreenMedia {
            width: 100% !important;
            border-radius: 13px;
        }
    }

    &__topBlockText {
        flex: 4;
        @include flexCol();
        flex-direction: column;

        @include mdScreenMedia {
            text-align: center;
        }
    }

    &__topBlockSubtitle {
        margin-bottom: 7px;

        @include mdScreenMedia {
            text-align: center;
        }
    }

    &__topBlockTitle {
        @include setFont(30px, 400);
        margin-bottom: 34px;

        @include smScreenMedia {
            margin-bottom: 20px;
            font-size: 25px;
        }
    }

    &__topBlockInstructions {
        @include flexCol(flex-start, flex-start);
        gap: 8px;

        &:first-child {
            margin-bottom: 34px;

            @include smScreenMedia {
                margin-bottom: 20px;
            }
        }
    }

    &__topBlockInstructionsSuccessBox {
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $color-main;
        @include flexCol();
        position: relative;

        @include xlScreenMedia {
            display: none;
        }
    }

    &__topBlockInstructionsSuccessIcon {
        @include svg(8px, transparent, 8px, $color-white);
    }

    &__topBlockInstructionsArrowIcon {
        position: absolute;
        bottom: 0;
        transform: translateY(calc(100% - 6px));
        right: 40%;
        @include svg(116px, $color-white, 147px);
        z-index: 777;
    }

    &__topBlockInstructionsText {
        @include xlScreenMedia {
            text-align: center;
        }
    }

    &__bottomBlock {
        @include flexCol();
        gap: 20px;

        @include lgScreenMedia {
            flex-direction: column;
            gap: 50px;
        }
    }

    &__bottomBlockContainer {
        flex: 1;
        @include flexCol(center, flex-end);

        &:first-child {
            background-image: url('../../../assets/images/MainPage/newsbottomBlock1Bg.jpg');
            padding-top: 44px;
        }

        &:last-child {
            background-image: url('../../../assets/images/MainPage/newsbottomBlock2Bg.jpg');
            padding-top: 32px;
        }

        @include lgScreenMedia {
            width: 100%;
        }

        @include smScreenMedia {
            padding-top: 0 !important;
            background-image: none !important;
        }
    }

    &__bottomBlockImg {
        border-radius: 13px 13px 0 0;
        overflow: hidden;

        &_left {
            max-width: 70%;
        }

        &_right {
            max-width: 76%;
        }

        @include smScreenMedia {
            max-width: 100% !important;
            width: 100%;
            border-radius: 13px;
        }
    }

    &__absoluteBlock {
        left: 50%;
        bottom: 0;
        transform: translate(-50%, 50%);
        z-index: 666;
    }

    &__linearBox {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: linear-gradient(
            0deg,
            rgba(16, 19, 40, 1) 0%,
            rgba(16, 19, 40, 0)
        );
        height: 120px;
        z-index: 555;
        border-radius: 0 0 17px 17px;

        &_bottom {
            @include smScreenMedia {
                display: none;
            }
        }
    }
}
