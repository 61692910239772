@import 'vars';

@mixin smScreenMedia {
    @media (max-width: $sm-screen) {
        @content;
    }
}

@mixin mdScreenMedia {
    @media (max-width: $md-screen) {
        @content;
    }
}

@mixin lgScreenMedia {
    @media (max-width: $lg-screen) {
        @content;
    }
}

@mixin xlScreenMedia {
    @media (max-width: $xl-screen) {
        @content;
    }
}

@mixin setDistance($prop, $distance) {
    @if ($distance == 120) {
        #{$prop}: 120px;

        @include xxlScreenMedia {
            #{$prop}: 110px;
        }

        @include xlScreenMedia {
            #{$prop}: 100px;
        }

        @include lgScreenMedia {
            #{$prop}: 80px;
        }

        @include smScreenMedia {
            #{$prop}: 40px;
        }
    } @else if ($distance == 60) {
        #{$prop}: 60px;

        @include xxlScreenMedia {
            #{$prop}: 55px;
        }

        @include xlScreenMedia {
            #{$prop}: 50px;
        }

        @include lgScreenMedia {
            #{$prop}: 40px;
        }

        @include smScreenMedia {
            #{$prop}: 20px;
        }
    } @else if ($distance == 140) {
        #{$prop}: 140px;

        @include xxlScreenMedia {
            #{$prop}: 120px;
        }

        @include xlScreenMedia {
            #{$prop}: 100px;
        }

        @include lgScreenMedia {
            #{$prop}: 70px;
        }

        @include smScreenMedia {
            #{$prop}: 40px;
        }
    } @else if ($distance == 70) {
        #{$prop}: 70px;

        @include xxlScreenMedia {
            #{$prop}: 60px;
        }

        @include xlScreenMedia {
            #{$prop}: 50px;
        }

        @include lgScreenMedia {
            #{$prop}: 35px;
        }

        @include smScreenMedia {
            #{$prop}: 20px;
        }
    }
}

@mixin svg($width, $color, $height: $width, $stroke: none) {
    width: $width;
    height: $height;
    transition: $transition;
    fill: $color;
    stroke: $stroke;
}

@mixin setFont($size, $weight, $color: $color-white) {
    font-size: $size;
    font-weight: $weight;
    color: $color;
}

@mixin flexCol($layout: center, $align: center) {
    display: flex;
    justify-content: $layout;
    align-items: $align;
}

@mixin hover {
    @media (hover: hover) {
        @content;
    }
}

@mixin custom-scrollbar(
    $width: 4px,
    $bg-color: rgba(0, 0, 0, 1),
    $thumb-color: rgba(99, 99, 125, 1),
    $radius: 20px,
    $height: 4px
) {
    &::-webkit-scrollbar {
        width: $width;
        height: $height;
        background-color: $bg-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $thumb-color;
        border-radius: $radius;
    }

    &::-webkit-scrollbar-track {
        background-color: $bg-color;
        border-radius: $radius;
    }

    @-moz-document url-prefix() {
        scrollbar-width: thin;
        scrollbar-color: $thumb-color $bg-color;
    }

    @supports not (scrollbar-width: thin) {
        overflow: auto;
    }
}

@mixin gradient-border(
    $border-radius: 16px,
    $border-width: 1px,
    $bg-primary:
        radial-gradient(
            57.84% 29.32% at 0% 1.75%,
            rgba(174, 174, 186, 0.877) 0%,
            rgba(21, 21, 30, 0) 100%
        ),
    $bg-secondary: rgba(86, 86, 127, 1)
) {
    border-radius: $border-radius;

    &::before {
        content: '';
        position: absolute;
        pointer-events: none;
        inset: 0;
        padding: $border-width;
        border-radius: $border-radius;
        background: $bg-primary, $bg-secondary;

        -webkit-mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);
        mask:
            linear-gradient(#fff 0 0) content-box,
            linear-gradient(#fff 0 0);

        -webkit-mask-composite: xor;
        mask-composite: exclude;
    }
}

@mixin fade($name, $from, $to, $duration, $timing) {
    @keyframes #{$name} {
        from {
            opacity: $from;
        }
        to {
            opacity: $to;
        }
    }
}

@mixin fadeBg($name, $from, $to, $duration, $timing, $toExtraBg: null) {
    @keyframes #{$name} {
        from {
            background: $from;
        }
        to {
            background: $to, $toExtraBg;
        }
    }
}

@mixin animate(
    $name,
    $fromTransform,
    $toTransform,
    $fromOpacity,
    $toOpacity,
    $duration,
    $timing
) {
    @keyframes #{$name} {
        from {
            transform: $fromTransform;
            opacity: $fromOpacity;
        }
        to {
            transform: $toTransform;
            opacity: $toOpacity;
        }
    }
}
