@import '../../../../../styles/mixins';
@import '../../../../../styles/vars';

.sideBarLinks {
    // 1. Header styles
    &__header {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        display: flex;
        padding-top: 10px;
        padding-left: 16px;
        padding-right: 16px;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        height: 96px;

        &_compact {
            width: 79px;
            padding: 15px 0 0 0 !important;
            justify-content: center;
        }
    }

    &__logoImage {
        object-fit: contain;
        padding-top: 10px;
        padding-bottom: 10px;
        transition: width 0.3s ease-in-out;

        &_full {
            width: 150px;
            height: 72px;
            min-width: 150px;
        }

        &_compact {
            width: 56px;
            max-width: 56px;
        }
    }

    &__closeBtn {
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
    }

    &__closeIcon {
        @include svg(32px, none);
    }

    // 2. Container styles
    &__container {
        padding-block: 120px 0;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: calc(100vh - 96px);
        height: calc(100% - 96px);
        &::-webkit-scrollbar {
            display: none !important;
            width: 0 !important;
            background: transparent !important;
        }
        scrollbar-width: none !important;
        -ms-overflow-style: none !important;
        transition: width 0.3s ease-out;
        .sideBarLinks__linkText {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            font-family: 'Inter', sans-serif;
            font-weight: 400;
            font-size: 14px;
            transition: width 5.3s ease-out;

        }

        &_compact {
            width: 80px;
            transition: width 5.3s ease-out;
            overflow-y: auto;
            overflow-x: hidden;
            display: flex;
            flex-direction: column;
            padding: 120px 0 0 0;
            position: relative;
            max-height: calc(100vh - 96px);
            height: calc(100% - 96px);

            &::-webkit-scrollbar {
                display: none !important;
                width: 0 !important;
                background: transparent !important;
            }
            scrollbar-width: none !important;
            -ms-overflow-style: none !important;

            .sideBarLinks__link {
                position: relative;
            }

            .sideBarLinks__link__activeIndicator {
                left: 0;
                position: absolute;
            }

            

            .sideBarLinks__linkIcon {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin-left: 0; // Убираем margin-left
            }

            .sideBarLinks__linkArrowIcon {
                display: none;
            }

            .sideBarLinks__linkText {
                opacity: 0;
                visibility: hidden; // Было visible
                transform: translateX(-10px); // Было translateX(0)
                transition:
                    opacity 0.3s ease-in-out,
                    transform 0.3s ease-in-out,
                    visibility 0s linear 0.3s;
            }
        }

        .sideBarLinks__closeBtn {
            margin: 0 auto;
        }
    }

    &__line {
        width: 100%;
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 10px 0;
        position: relative;
        box-shadow: 0px 1px 10px 3px #202337;
    }

    &__linebtm {
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 10px 16px;
        position: relative;
        box-shadow: 0px 0px 10px 1px #20233781;
    }

    // 3. Link styles
    &__link {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;
        height: 46px;
        min-height: 46px;
        padding: 13px 20px 13px 0px;
        background: transparent;
        @include flexCol(flex-start);
        transition: all 0.6s ease-in-out;
        @include setFont(17px, 600, $color-grey);
        text-decoration: none;
        gap: 10px;

        .sideBarLinks__linkText {
            position: absolute;
            left: 52px;
            white-space: nowrap;
            display: block;
        }

        &__activeIndicator {
            position: absolute;
            left: -4px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
        }

        &_active {
            background: $gradient-default;
            color: $color-white;
            height: 43px;

            .sideBarLinks__linkText {
                transition: transform 0.3s ease;
            }

            .sideBarLinks__linkArrowIcon {
                margin-left: auto;
                transform: rotate(180deg);
            }
        }

        &Icon {
            width: 20px;
            height: 20px;
            min-height: 20px;
            min-width: 20px;
            margin-left: 20px;
            display: block;
            position: absolute;
        }

        &ArrowIcon {
            width: 18px;
            height: 18px;
            margin-left: auto;
        }
    }

    // 4. Accordion styles
    &__acardeon {
        position: relative;
        padding-bottom: 10px;
        padding-top: 10px;
        border-bottom: 1px solid;
        border-image: radial-gradient(
                58.91% 82.04% at 50.57% 100.97%,
                #5e76ec 0%,
                #14141e 100%
            )
            1;
    }

    &__acardeonItem {
        padding: 4px 0;
        @include flexCol(flex-start);
        gap: 20px;
        animation: fadeInFromTop 0.3s ease-in-out;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-delay: calc(0.1s * var(--item-index));
        margin-left: 50px;
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 300;
        letter-spacing: 0.02em;
        color:#6A6A91;
        text-decoration: none;
        position: relative;
        z-index: 1;
        transition: color 0.3s ease;
        @keyframes fadeInFromTop {
            0% {
                opacity: 0;
                transform: translateY(-5px);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), transparent);
            }
            100% {
                opacity: 1;
                transform: translateY(0);
                background: transparent;
            }
        }
    
        &:hover {
            color: #FFFFFF;
        }
    
        &_active {
            color: #FFFFFF;
            font-weight: 600;
        }
        &.closing {
            animation: fadeOutToTop 0.3s ease-in-out;
        }
    
        @keyframes fadeOutToTop {
            0% {
                opacity: 1;
                transform: translateY(0);
                background: transparent;
            }
            100% {
                opacity: 0;
                transform: translateY(-5px);
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0), transparent);
            }
        }
    }

    &__acardeonItemLine {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #515180;
        position: relative;
        z-index: 2;

        .sideBarLinks__acardeonItem_active & {
            background-color: #899cff;
            box-shadow: 0 0 8px #5b8cf4;
        }
    }

    
    

    // 5. Modal styles
    &__modal {
        position: fixed;
        z-index: 4;
        top: 200px;
        left: 60%;
        transform: translateX(-50%);
        width: 60%;
        background-color: $color-blue-passive;
        color: $color-white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 20px;
        @include setFont(18px, 700);

        @include mdScreenMedia {
            width: 100%;
            left: 0;
            transform: translateX(0);
        }
    }

    &__modalClose {
        border: none;
        cursor: pointer;
        background-color: transparent;
        margin-left: auto;
    }

    &__modalCloseIcon {
        @include svg(20px, $color-white, 20px, $color-white);
    }

    // 6. Social styles
    &__socials {
        display: flex;
        gap: 16px;
        padding: 10px;
        margin: 0 5px;
    }

    &__socialIcon {
        width: 40px;
        height: 40px;
    }

    &__compactSocialIcon {
        bottom: 10px;
        width: 45px;
        height: 72px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        margin-left: 16px;

        &Background {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #262525;
            border-radius: 6px;
            border: 1px solid rgba(255, 255, 255, 0.03);
        }
    }
}
