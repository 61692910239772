@import '../../../../../styles/mixins';

.sideBarFooter {
    
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding-bottom: 10px;

    
    &__logoutBtn {
        width: 100%;
        background-color: $color-blue-passive;
        border-radius: 68px;
        padding: 13px 0;
        @include flexCol();
        gap: 5px;
        border: none;
        cursor: pointer;
    }

    &__rectangle {
        height: 66px;
        margin: 0px 16px;
        border-radius: 6px;
        background: linear-gradient(#0B0B1B, #0B0B1B) padding-box,
                    linear-gradient(181.82deg, #899CFF 1.5%, #273166 98.43%) border-box;
        border: 1px solid transparent;
    }

    &_compact {
        margin: 0px 16px 0px 16px;
    }

    &__socialBtn {
        border: none;
        background: transparent;
        cursor: pointer;
    }

    &__logoutIcon {
        @include svg(20px, $color-grey);

        &:hover {
            fill: $color-white;
        }
    }

    &__logoutText {
        @include setFont(14px, 600);
    }
}
