@import '../../../styles/vars';
@import '../../../styles/mixins';

.inputWrapper {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.inputHighlight {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
}

.input {
    width: 100%;
    padding: 12px;
    background-color: transparent;
    outline: none;
    border: none;
    border-radius: 10px;
    transition: $transition;

    @include setFont(14px, 700, $color-white);

    &:focus {
        outline: 2px solid $color-main;
    }

    &::placeholder {
        color: $color-grey;
    }
}

.inputFakeValueWrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    pointer-events: none;
    user-select: none;
    padding: 0 12px;
    gap: 14px;
    visibility: visible;
    z-index: 1;
}

.inputFakeValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 1px;
    max-width: 81%;

    @include setFont(14px, 700, transparent);
}

.suffix {
    display: flex;
    align-items: center;
    @include setFont(16px, 700, $color-grey);
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 6px;
    background-color: rgba(28, 35, 74, 1);
    border-radius: 8px;
    margin-left: 12px;
}
