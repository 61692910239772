@import '../../../styles/vars';

.walletIcon {
    width: 16px;
    height: 16px;
}

.headerContainer {
    margin-top: 40px;
    width: 100%;
    align-self: center;

    display: flex;
    flex-direction: column;
    gap: 42px;

    .title {
        font-family: Inter, sans-serif;
        font-size: 49.13px;
        font-weight: 400;
        line-height: 49.13px;
        letter-spacing: 0.07em;
        color: $color-white;
    }

    .headerBtns {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .headerBtn {
            display: flex;
            align-items: center;
            gap: 8px;

            padding: 14px 24px;

            background-color: rgba(88, 113, 242, 0.25);
            border: 1px solid rgba(88, 113, 242, 0.42);
            border-radius: 16px;

            &:hover {
                filter: brightness(140%);
            }

            .headerBtnText {
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 13px;
                letter-spacing: 0.07em;

                color: $color-white;
            }
        }
    }
}
