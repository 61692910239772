@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.hintContainer {
    position: absolute;
    top: -6px;
    left: -80%;
    width: 50%;
    transform: translateX(50%);

    background: rgba(7, 8, 19, 1);

    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 12px;
    gap: 12px;

    border: 1px solid rgba(43, 53, 108, 1);

    opacity: 0;
    pointer-events: none;
    transform: translateX(-10%);

    @include animate(
        slideFromLeft,
        translateX(-10%),
        translateX(50%),
        0,
        1,
        0.2s,
        ease-in-out
    );

    @include animate(
        slideToLeft,
        translateX(50%),
        translateX(-10%),
        1,
        0,
        0.2s,
        ease-in-out
    );

    &_visible {
        opacity: 1;
        pointer-events: all;
        transform: translateX(50%);
        animation: slideFromLeft 0.2s linear;
    }

    animation: slideToLeft 0.2s linear;

    .hintHeader {
        position: relative;
        display: flex;
        align-items: center;
        gap: 6px;

        .hintHighlight {
            width: 2px;
            height: 100%;
            position: absolute;
            top: 50%;
            right: -12.5px;
            transform: translateY(-50%) rotateY(180deg);
        }

        .hintTitle {
            font-family: Inter, sans-serif;
            font-size: 13.68px;
            font-weight: 400;
            line-height: 11px;
            color: $color-white;
        }

        .closeBtn {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            padding: 4px 8px;

            background: rgba(137, 156, 255, 0.14);
            border: none;
            border-radius: 4px;

            font-family: Inter, sans-serif;
            font-size: 8px;
            font-weight: 400;
            line-height: 7px;
            color: rgba(137, 156, 255, 1);
        }
    }

    .hintInfoList {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .hintText {
            font-family: Inter, sans-serif;
            font-size: 10.26px;
            font-weight: 400;
            line-height: 10px;
            color: rgba(106, 106, 145, 1);

            .hintSubTitle {
                color: rgba(158, 158, 184, 1);
                font-weight: 500;
            }
        }
    }
}
