@import '../../../styles/vars';
@import '../../../styles/mixins';

.settingsBlock {
    padding-top: 35px;
    min-height: 100vh;
    padding-bottom: 20px;

    &__balanceTitle {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-right: auto;
    }

    &__balanceText {
        display: flex;
        flex-direction: column;
        gap: 4px;

        span {
            color: #9FA7DE;
            font-size: 14px;
            font-family: 'Inter', sans-serif;
        }
    }
    &__cryptoIcons {
        display: flex;
        gap: 16px;
        align-items: center;
    }

    &__cryptoItem {
        display: flex;
        align-items: center;
        gap: 8px;

        img {
            width: 24px;
            height: 24px;
        }

        span {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            color: #A0A0C4;
            font-weight: 300;
        }
    }

    &__boxHeader {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        min-height: 75px;

        & > button {
            min-width: 160px;
        }
    }
    .settingsBlock__description {
    color: #A0A0C4;
    font-size: 11px;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

.settingsBlock__walletRow {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 15% 30% 35% 20%;
    padding: 5px;
    color: #A0A0C4;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 10px;
    margin-top: 5px;
    align-items: center;
    .column {
        font-size: 10px;
    }
}

.addressWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.defaultCheckbox {
    width: 20px;
    height: 20px;
    border: 1.5px solid #BAC4F7;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
  
    &.active {
      border-color: #5871F2;
  
      svg {
        opacity: 1;
      }
    }
  
    svg {
      opacity: 0;
      transition: opacity 0.2s;
    }
  }
  


  &__gradientBlock {
    width: 100%;
    height: 180px;
    padding: 16px;
    background: #080915;
    border: 1px solid rgba(137, 156, 255, 0.32);
    border-radius: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    
}
  .addressWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    
    span {
      color: #fff;
      font-size: 16px;
      font-family: 'Inter', sans-serif;
      font-weight: 300;
    }
  }

  .actionIcons {
    display: flex;
    gap: 12px;
    align-items: center;
  }


    &__mainBlock {
        display: flex;
        gap: 35px;

        .settingsBlock__box {
            flex: 1;
        }

        @include lgScreenMedia {
            flex-direction: column;
        }

        @include smScreenMedia {
            gap: 25px;
            margin-bottom: 25px;
        }
    }

    @include smScreenMedia {
        margin-top: 25px;
    }

    &__routeTitle {
        @include smScreenMedia {
            margin-bottom: 15px;
        }
    }

    &__line {
        width: 100%;
        height: 0.5px;
        border: 0.5px solid;
        border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
        border-image-slice: 1;
        margin: 20px 0;
        position: relative;
        display: block;
    }

    &__fieldTitle {
        margin-bottom: 8px;
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 10px;
    }

    &__main {
        border-radius: 16px;
        padding: 30px;

        display: flex;
        flex-direction: column;
        gap: 35px;

        @include smScreenMedia {
            padding: 0;
            background: transparent;
            gap: 25px;
        }
    }

    .settingsBlock__walletText {
    display: inline;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    text-align: left;
}

.defaultWallet {
    color: #F25858;
    font-weight: 500;
}

.settingsBlock__columns {
    display: grid;
    flex-direction: row;
    grid-template-columns: 20% 25% 40% 15%;
    justify-content: space-between;
    padding: 0 16px;
    font-size: 10px;
    color: #A0A0C4;
}

.column {
    margin-right: 20px;
    text-align: left;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 400;
}

.editNameBlock {
    width: 100%;

    input {
        background: #131832;
        border: 1px solid #5871F26B;
        border-radius: 12px;
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        padding: 8px 12px;
        width: 100%;
        outline: none;
    }
}

.editAddressBlock {
    position: relative;
    width: 100%;

    svg {
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
    }

    input {
        background: #131832;
        border: 1px solid #5871F26B;
        border-radius: 12px;
        color: #fff;
        font-family: 'Inter', sans-serif;
        font-size: 16px;
        padding: 8px 12px 8px 40px;
        width: 100%;
        outline: none;
    }
}

.addressWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.saveButton {
    width: 57px;
    height: 40px;
    padding: 2px 4px;
    gap: 2.62px;
    border-radius: 12px;
    background: #5871F2;
    border: none;
    cursor: pointer;
    font-family: Inter;
    font-size: 12px;
    font-weight: 300;
    line-height: 14.52px;
    color: white;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    justify-content: center;
    align-items: center;
}



.walletName {
    font-size: 16px;
    color: #FFFFFF;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
}

.description {
    color: #A0A0C4;
    font-size: 12px;
}

    &__mainBlock {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 35px;

        @include lgScreenMedia {
            grid-template-columns: 1fr;
        }

        @include smScreenMedia {
            gap: 25px;
            margin-bottom: 25px;
        }
    }

    &__boxHeader {
        display: flex;
        align-items: center;

        @include smScreenMedia {
            flex-direction: column;
        }

        & > button {
            width: 30%;

            @include setFont(16px, 500);

            @include smScreenMedia {
                width: 100%;
            }
        }
    }

    &__addWalletBtn {
        width: 100%;
        height: 52px;
        padding: 0 16px;
        gap: 6px;
        border-radius: 12px;
        background: #5871F2;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        transition: opacity 0.3s ease;
    
        &:hover {
          opacity: 0.9;
        }
    
        span {
          color: #FFFFFF;
          font-family: 'Inter', sans-serif;
          font-size: 16px;
          font-weight: 500;
        }
      }

    &__box {
        background: #09090E;
        border-radius: 24px;
        border: 1px solid #383C54;
        padding: 30px 25px;
        width: 100%;

        @include smScreenMedia {
            padding: 15px;
            border-radius: 16px;
        }
    }

    &__blockContainer {
        display: flex;
        gap: 7.11px;
        margin: 5px 0;
    }
    
    &__infoBlock {
        width: 189.17px;
        height: 38px;
        border-radius: 6.4px;
        background: #13141F;
        display: flex;
        cursor: pointer;
        transition: all 0.3s ease;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;
    
        &:hover {
            border: 1px solid #25DAB2;
            box-shadow: 0px 4px 9.4px -1px #25DAB240;
        }
    
        &.active {
            border: 1px solid #25DAB2;
            box-shadow: 0px 4px 9.4px -1px #25DAB240;
        }
    }

    &__boxSubtitle {
        font-weight: 600;
        font-size: 24px;
        font-family: 'Inter', sans-serif;
        color: #ffffff;
        @include smScreenMedia {
            width: 100%;
        }
    }
    &__borderLine {
        width: 100%;
        height: 1px;
        background: #323232;
        margin: 0 0 10px 0;
    }

    .actionIcons {
        display: flex;
        gap: 8px;
        align-items: center;
    
        svg {
            cursor: pointer;
        }
    }

    &__boxTitle {
        @include setFont(18px, 600);
        margin: 0 16px 12px;
        display: flex;
        justify-content: space-between;

        @include smScreenMedia {
            margin: 8px 0 6px;
        }

        & > p {
            display: flex;
            flex-direction: row; 
            align-items: center;
            gap: 20px;
            @include mdScreenMedia {
                display: none;
            }
    
            & > span {
                margin-left: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-family: 'Inter', sans-serif;
                font-size: 12px;
                align-items: center;
                gap: 8px;
    
                &:first-child::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    display: block;
                    background-image: url('../../../assets/icons/telegram.svg');
                }
    
                &:last-child::before {
                    content: '';
                    width: 18px;
                    height: 18px;
                    display: block;
                    background-image: url('../../../assets/icons/browser.svg');
                }
            }
        }
    }

    &__boxNotifications {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }
}
