.chainsBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
}

.chainBtn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(17, 18, 28, 1);
    border-radius: 6px;
    border: 1px solid transparent;
    cursor: pointer;

    &_sol {
        &:hover {
            border-color: rgba(37, 218, 178, 0.48);
        }

        &:disabled {
            border-color: rgba(37, 218, 178, 1);
            box-shadow: 0px 4px 9.4px -1px rgba(37, 218, 178, 0.25);
        }
    }

    &_eth {
        &:hover {
            border-color: rgba(88, 113, 242, 0.48);
        }

        &:disabled {
            border-color: rgba(88, 113, 242, 1);
            box-shadow: 0px 4px 9.4px -1px rgba(88, 113, 242, 0.25);
        }
    }

    &_bsc {
        &:hover {
            border-color: rgba(240, 185, 11, 0.48);
        }

        &:disabled {
            border-color: rgba(240, 185, 11, 1);
            box-shadow: 0px 4px 9.4px -1px rgba(240, 185, 11, 0.25);
        }
    }
}
