@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.infoBlock {
    display: grid;
    grid-template-columns: 1.2fr 1fr 1.2fr;
    gap: 6px;
    align-items: center;

    .priceContainer {
        display: flex;
        align-items: center;
        gap: 4px;

        .switchBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 4px 6px;
            border-radius: 4px;
            background-color: rgba(39, 49, 102, 0.53);
            border: none;

            &:hover {
                filter: brightness(140%);
            }

            .switchIcon {
                width: 8px;
                height: 8px;
            }
        }
    }
}

.subTitle {
    font-family: Inter, sans-serif;
    font-size: 13.45px;
    font-weight: 400;
    line-height: 12.5px;

    color: $color-white;

    text-wrap: nowrap;

    .amountHighlight {
        font-weight: 600;
    }

    &_center {
        justify-self: center;

        font-size: 15.69px;
        line-height: 14.7px;
    }

    &_right {
        justify-self: flex-end;
    }

    .highlightedInfo {
        color: rgba(193, 202, 252, 1);
    }
}
