.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
}

.modal {
    position: fixed;
    width: 388px;
    height: 490px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px 0 0 0;
    border-radius: 16px;
    background: linear-gradient(0deg, #0c0c18, #0a0a15),
                linear-gradient(177.43deg, rgba(11, 13, 22, 0.522) 0.8%, rgba(10, 10, 13, 0.24) 99.2%);
    border: 1px solid #27316633;
    display: flex;
    flex-direction: column;
    gap: 14px;
    z-index: 101;

    &__select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative; 
        cursor: pointer;
        
        svg {
            margin-left: 8px;
        }
    }

    &__selectDropdown {
        position: absolute;
        left: 0;
        top: calc(100% + 8px);
        width: 100%;
        background: #131832;
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 12px;
        z-index: 102;
        overflow: hidden;
    }

    &__selectItem {
        width: 100%;
        padding: 12px 16px;
        display: flex;
        align-items: center;
        gap: 12px;
        border: none;
        background: transparent;
        color: white;
        cursor: pointer;
        transition: background 0.2s;
        position: relative;

        &:hover {
            background: linear-gradient(90deg, rgba(137, 156, 255, 0.12) 0%, rgba(49, 81, 251, 0.02) 100%),
            radial-gradient(32.25% 112.5% at 0% 50%, rgba(137, 156, 255, 0.101333) 0%, rgba(21, 21, 30, 0) 100%);

            &::before {
                content: '';
                position: absolute;
                left: -1px;
                top: 50%;
                transform: translateY(-50%);
                width: 2px;
                height: 36px;
                background: #899CFF;
                border-radius: 0px 4.29px 4.29px 0px;
            }
            }

        img {
            width: 24px;
            height: 24px;
        }
    }

    &__header {
        h2 {
            font-family: Inter;
            font-size: 28px;
            font-weight: 600;
            line-height: 44.64px;
            text-align: center;
            color: white;
        }
    }

   

    &__content {
        padding: 0 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__field {
        label {
            display: block;
            color: white;
            margin-bottom: 8px;
            font-family: 'Inter';
            font-size: 10px;
            font-weight: 400;
        }

        input, .modal__select {
            width: 100%;
            height: 38px;
            padding: 12px 16px;
            border-radius: 12px;
            color: #fff;
            font-size: 10px;
            font-weight: 400;
            font-family: 'Inter';
            background: #131832;
            border: 1px solid rgba(255, 255, 255, 0.1);
        }
    }

    &__buttons {
        padding: 0 24px;
        display: flex;
        gap: 16px;

        button {
            width: 100%;
            height: 42px;
            padding: 0 16px;
            border-radius: 16px;
            font-weight: 600;
            cursor: pointer;
        }
    }
    &__gradientDivider {
        width: calc(100% - 48px);
        height: 1px;
        border: 0.5px solid;
        border-image-source: radial-gradient(49.82% 4015.78% at 48.9% 0%, rgba(255, 255, 255, 0.21) 0%, rgba(39, 49, 102, 0.21) 100%);
        border-image-slice: 1;
        margin: 0 auto;
    }

    &__buttonCancel {
        background: rgba(88, 113, 242, 0.08);
        border: 1px solid rgba(88, 113, 242, 0.42);
        color: #5871F2;
    }

    &__buttonConfirm {
        background: #5871F2;
        border: none;
        color: white;
    }
}
