

.arbitrageHeader {
    padding: 34px 30px 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__panel {
        width: 467px;
        height: 49px;
        padding: 0px 14px;
        gap: 13px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(15.4px);
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    &__navButton {
        background: none;
        border: none;
        color: #6A6A91;
        font-size: 12px;
        cursor: pointer;
        padding: 0;
        transition: color 0.2s ease;

        &_active {
            color: #ffffff;
        }
    }

    &__divider {
        width: 1px;
        height: 24px;
        background: rgba(255, 255, 255, 0.1);
    }
}

