@import '../../../styles/vars';
@import '../../../styles/mixins';

.subscriptionBlock {
    &__container {
        color: $color-white;
        height: 80vh;
        background-color: $color-black-bold;
        border-radius: 16px;
        padding: 20px;
        gap: 20px;
        display: grid;
        grid-template-columns: 1fr 2fr;

        @include smScreenMedia {
            display: flex;
            flex-direction: column;
            background-color: transparent;
            padding: 0;
            max-height: 200vh;
        }
    }

    &__infoModal {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $color-black-bold;
        padding: 20px;
        border-radius: 10px;

        @include setFont(16px, 600, $color-main);

        @include smScreenMedia {
            position: fixed;
            top: 80px;
            width: 95%;
        }
    }

    &__subModal {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: $color-black-bold;
        border: 4px solid $color-main;
        padding: 20px;
        border-radius: 10px;
        width: 50%;
        height: 35%;

        @include setFont(18px, 600);

        @include smScreenMedia {
            width: 100%;
            height: 50%;
            position: fixed;
        }
    }

    &__subModalContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        background-color: $color-blue-bold;
        height: 100%;
        border-radius: 16px;
    }

    &__subModalHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__subModalHeaderBtn {
        padding: 4px;
        border: none;
        background-color: transparent;
    }

    &__subModalHeaderCrossIcon {
        @include svg(16px, transparent, 16px, $color-white);
    }

    &__subModalLink {
        display: flex;
        align-items: center;
        gap: 6px;

        @include setFont(18px, 600, $color-white);
    }

    &__subModalLinkIcon {
        @include svg(22px, $color-white);
    }

    &__option {
        display: flex;
        justify-content: space-between;
    }

    &__newSubsContainer {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @include smScreenMedia {
            display: flex;
            flex-direction: column;
            gap: 10px;
        }
    }

    &__newSubsInner {
        display: flex;
        flex-direction: column;
        gap: 20px;
        height: 100%;
    }

    &__newSectionsBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        padding-right: 12px;
        margin-bottom: auto;

        @include smScreenMedia {
            display: flex;
            flex-direction: column;
        }
    }

    &__newSection {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px;
        background-color: $color-black-bold;
        border-radius: 10px;

        @include smScreenMedia {
            height: 60px;
            & > span {
                width: 50%;
                @include setFont(12px, 500);
                text-wrap: wrap;
            }
        }

        @include setFont(12px, 600);

        & > button {
            width: 100px;
            padding: 10px;
            @include setFont(12px, 600);
        }
    }

    &__newSectionBtns {
        display: flex;
        gap: 10px;

        & > button {
            width: 100px;
            padding: 10px;
            @include setFont(12px, 600);
        }
    }

    &__box {
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        padding: 20px;
        background-color: $color-blue-bold;
        border-radius: 24px;

        &:nth-last-of-type(1) {
            @include smScreenMedia {
                min-height: 150vh;
            }
        }

        @include smScreenMedia {
            min-height: 50vh;
        }
    }

    &__currentSections {
        display: flex;
        flex-direction: column;
        gap: 6px;
        overflow-y: auto;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
        padding-right: 12px;

        @include setFont(14px, 600);
    }

    &__currentSection {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        background-color: $color-black-bold;
        border-radius: 10px;
    }

    &__currentSectionLvl {
        color: $color-main;
    }

    &__price {
        color: $color-light-green;
    }

    &__submitBox {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        & > button {
            width: 40%;
        }

        @include smScreenMedia {
            flex-direction: column;
            align-items: start;
            gap: 10px;

            @include setFont(14px, 500);

            & > button {
                width: 100%;
            }
        }
    }

    &__newSubsBlock {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100%;
        padding-right: 12px;
        overflow-y: auto;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: 0;
    }

    &__newSubBlockInner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        & > button {
            width: calc(50% - 10px);
        }

        &_withSelects {
            @include smScreenMedia {
                flex-direction: column;
            }
        }
    }
}
