@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.CEXOutflowsListItem {
    width: 100%;
    height: 76px;
    padding: 16px;
    background-color: linear-gradient(0deg, rgba(255, 255, 255, 0.02), rgba(255, 255, 255, 0.02)),
    radial-gradient(12.43% 9.59% at 0.08% 0.7%, rgba(255, 255, 255, 0.15) 0%, rgba(21, 21, 30, 0) 100%);
    border-radius: 16px;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    border: 1px solid rgba(255, 255, 255, 0.1);

    &__info {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &_grid {
            display: grid;
            grid-template-columns: 25% 30% 10%;
            gap: 20px;
            width: 100%;
        }

        &_column {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }

        &_label {
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 300;
            color:#6A6A91;

        }

        &_value {
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            font-weight: 500;
            color: #C1CAFC;
            display: flex;
            align-items: center;
            gap: 8px;
            svg {
                min-width: 18px;
                min-height: 18px;
            }
        }

        @include mdScreenMedia {
            flex-direction: column;
        }

        &_link {
            margin-left: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 230px;
            height: 42px;
            padding: 0 16px;
            gap: 8px;
            border-radius: 12px;
            background: #5871F2;
            text-decoration: none;
            color: #FFFFFF;
            font-family: 'Inter', sans-serif;
            font-size: 14px;
            font-weight: 400;
            transition: background 0.2s ease;
        
            &:hover {
                background: darken(#5871F2, 5%);
            }
        
            @include mdScreenMedia {
                width: 100%;
                border-radius: 12px;
            }
        }

        &_linkIcon {
            @include svg(16px, #FFFFFF);
        
            @include mdScreenMedia {
                @include svg(12px, #FFFFFF);
            }
        }
    }

    &__hiderBtn {
        margin-left: 5px;
        cursor: pointer;
        background: transparent;
        border: none;

        @include mdScreenMedia {
            margin-left: 3px;
        }
    }

    &__reportIcon {
        transform: translateY(2px);
        @include svg(16px, $color-grey);

        @include mdScreenMedia {
            @include svg(12px, $color-grey);
        }
    }
}
