@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.settingsMenu {
    position: relative;
    z-index: 1;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .hintHighlight {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: -16px;
        width: 40%;
        height: 100%;
        background: linear-gradient(
            90deg,
            rgba(137, 156, 255, 0.22) 0%,
            rgba(20, 20, 31, 0) 100%
        );

        @include fade(fadeIn, 0, 1, 0.2s, linear);
        @include fade(fadeOut, 1, 0, 0.2s, linear);

        animation: fadeOut 0.2s linear;

        &_visible {
            opacity: 1;
            animation: fadeIn 0.2s linear;
        }
    }

    .optionsBlock {
        display: flex;
        gap: 14px;
        align-items: center;

        .optionsBtn {
            cursor: pointer;
            padding: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            border: none;
            background: rgba(160, 160, 196, 0.12);
        }

        .optionsText {
            display: flex;
            align-items: flex-start;
            gap: 2px;

            .descriptionText {
                color: rgba(160, 160, 196, 1);

                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 300;
                line-height: 14.88px;
            }

            .valueText {
                color: $color-white;

                font-family: Inter, sans-serif;
                font-size: 13.45px;
                font-weight: 400;
                line-height: 13.45px;
            }
        }
    }

    .extendedSettings {
        position: absolute;
        z-index: 100;
        top: auto;
        bottom: 0;
        opacity: 0;
        pointer-events: none;
        transform: translateY(150%);
        left: 0;
        width: 50%;

        display: flex;
        flex-direction: column;
        padding: 12px;
        gap: 12px;

        border-radius: 10px;
        background: rgba(7, 8, 19, 1);
        border: 1px solid rgba(43, 53, 108, 1);

        @include animate(
            slideFromBtm,
            translateY(150%),
            translateY(100%),
            0,
            1,
            0.2s,
            ease-out
        );
        @include animate(
            slideToBtm,
            translateY(100%),
            translateY(150%),
            1,
            0,
            0.2s,
            ease-out
        );

        animation: slideToBtm 0.2s ease-out;

        &_visible {
            animation: slideFromBtm 0.2s ease-out;
            opacity: 1;
            pointer-events: all;
            transform: translateY(100%);
        }

        .saveBtn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            padding: 5.5px;
            border: none;
            background: rgba(88, 113, 242, 1);

            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 500;
            line-height: 17.28px;
            letter-spacing: 0.07em;
            color: $color-white;

            &:hover {
                filter: brightness(115%);
            }
        }

        .inputContainer {
            display: flex;
            flex-direction: column;
            gap: 6px;

            .inputTitle {
                font-family: Inter, sans-serif;
                font-size: 8px;
                font-weight: 400;
                line-height: 9.92px;

                color: $color-white;
            }

            .inputWrapper {
                height: 28px;
                position: relative;
                display: flex;
                align-items: center;
                border: 0.5px solid rgba(88, 113, 242, 0.42);
                padding: 0 7.5px;
                border-radius: 8px;
                justify-content: space-between;
                background: rgba(19, 24, 50, 1);
                overflow: hidden;

                &:hover {
                    border: 0.5px solid rgba(88, 113, 242, 0.75);
                }

                &_active {
                    border: 0.5px solid rgba(88, 113, 242, 0.9);

                    &:hover {
                        border: 0.5px solid rgba(88, 113, 242, 0.9);
                    }

                    background: radial-gradient(
                            14.11% 89.93% at 0% 50%,
                            rgba(137, 156, 255, 0.26) 0%,
                            rgba(21, 21, 30, 0) 100%
                        ),
                        #131832;
                }

                .inputHighlight {
                    position: absolute;
                    opacity: 0;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    height: 60%;

                    @include fade(fadeIn, 0, 1, 0.2s, ease-in-out);
                    @include fade(fadeOut, 1, 0, 0.2s, ease-in-out);

                    animation: fadeOut 0.1s ease-in-out;

                    &_active {
                        opacity: 1;
                        animation: fadeIn 0.1s ease-in-out;
                    }
                }

                .input {
                    background-color: transparent;
                    border: none;
                    width: 70%;

                    font-family: Inter, sans-serif;

                    font-weight: 400;
                    line-height: 14px;
                    color: $color-white;

                    &:focus {
                        outline: none;
                    }

                    &:focus-visible {
                        outline: none;
                    }
                }

                .inputLabel {
                    font-family: Inter, sans-serif;
                    font-size: 8px;
                    font-weight: 300;
                    line-height: 0.8;

                    color: rgba(160, 160, 196, 1);
                }
            }
        }
    }
}
