@import '../../../styles/vars';

.positionsContainer {
    width: 100%;
    height: 100%;
    background: linear-gradient(
            177.43deg,
            rgba(44, 50, 82, 0.24) 0.8%,
            rgba(10, 10, 13, 0.24) 99.2%
        ),
        #09090e;
    padding: 16px;

    border: 1px solid rgba(36, 36, 57, 1);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .positionsHeader {
        display: flex;
        justify-content: space-between;

        .title {
            font-family: Inter, sans-serif;
            font-size: 24px;
            font-weight: 600;
            line-height: 24px;
            color: rgba(160, 160, 196, 1);
        }
    }
}

.headerMenu {
    max-width: 40%;
    flex-direction: row-reverse;
    justify-content: flex-start;
    gap: 6px;
}

.chainBtn {
    background: rgba(14, 14, 22, 1);
}

.posTable {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .posTableList {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .posListDivider {
            background-color: rgba(31, 33, 51, 1);
            width: 100%;
            height: 1px;
        }

        .posTableItem {
            display: grid;
            grid-template-columns: repeat(7, 1fr) 25%;

            .posItemFace {
                display: flex;
                gap: 6px;
                align-items: center;

                .posItemName {
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 11px;

                    color: $color-white;
                }

                .posImgContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    width: 24px;
                    height: 24px;

                    background: rgba(0, 0, 0, 1);

                    & > img {
                        width: 55%;
                        height: 55%;
                        border-radius: 4px;
                    }
                }
            }

            .posItemInfo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 6px;

                &_last {
                    align-items: flex-end;
                }

                .posItemNativePrice {
                    display: flex;
                    align-items: center;
                    gap: 3px;

                    & > img {
                        width: 12px;
                    }
                }
            }
        }
    }

    .posTableHeader {
        display: grid;
        grid-template-columns: repeat(7, 1fr) 25%;

        font-family: Inter, sans-serif;
        font-size: 10px;
        font-weight: 400;
        line-height: 9px;
        text-align: center;
        color: rgba(160, 160, 196, 1);
        text-wrap: nowrap;

        > :first-child {
            text-align: left;
        }
        > :last-child {
            text-align: right;
        }
    }
}

.posItemTitle {
    font-family: Rubik;
    font-size: 14px;
    font-weight: 400;
    line-height: 13px;

    color: $color-white;

    &_green {
        color: rgba(88, 242, 114, 1);
    }

    &_red {
        color: rgba(242, 88, 88, 1);
    }
}

.posItemSubtitle {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 11px;
    color: rgba(160, 160, 196, 1);
}

.posItemWidget {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    padding: 6px;

    .posWidgetSideBtns {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px;
        background: rgba(14, 17, 36, 1);
        border-radius: 8px;

        .posWidgetSideBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            border: none;
            background: inherit;
            padding: 6px;
            border-radius: 8px;

            font-family: Inter;
            font-size: 10px;
            font-weight: 500;
            line-height: 9px;
            letter-spacing: 0.07em;
            color: rgba(160, 160, 196, 1);

            &_buy {
                background: rgba(105, 231, 134, 0.18);
                color: rgba(88, 242, 114, 1);
            }

            &_sell {
                background: rgba(242, 88, 88, 0.12);
                color: rgba(242, 88, 88, 1);
            }
        }
    }

    .posWidgetRangeInput {
        width: 100%;
        height: 12px;

        appearance: none;
        background-color: inherit;

        &::after {
            content: '';
            position: absolute;
            z-index: 1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            width: calc(100% - 8px);
            background-color: rgba(27, 27, 44, 1);
        }

        &::-webkit-appearance {
            display: none;
        }

        &::-webkit-slider-runnable-track {
            cursor: pointer;

            display: flex;

            width: 100%;
            height: 12px;

            border: none;
        }

        &::-webkit-slider-thumb {
            cursor: pointer;
            width: 12%;
            height: 90%;

            box-shadow: 0px 0px 2.7px 0px rgba(255, 255, 255, 0.16) inset;

            background: rgba(88, 113, 242, 1);
            border-radius: 6px;
            z-index: 10;
            appearance: none;
            border: none;

            &::-webkit-appearance {
                display: none;
            }
        }
    }

    .posWidgetSwapContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .posWidgetSwapBtn {
            background: rgba(88, 113, 242, 1);
            border: 0.5px solid rgba(48, 61, 131, 1);
            border-radius: 8px;
            width: 22%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            font-family: Inter, sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 9px;
            letter-spacing: 0.07em;
            color: $color-white;
        }

        .posWidgetSwapInputContainer {
            display: flex;
            align-items: center;
            border-radius: 8px;
            width: 75%;
            gap: 4px;
            border: 0.5px solid rgba(88, 113, 242, 0.42);
            background: rgba(19, 24, 50, 1);
            padding: 4px;

            .posWidgetSwapInputLabel {
                font-family: Inter;
                font-size: 7px;
                font-weight: 400;
                line-height: 6px;
                text-align: center;

                color: $color-white;

                padding: 4px;
                background: rgba(43, 53, 108, 1);
                border-radius: 4px;
            }

            .posWidgetSwapInput {
                background: inherit;
                width: 50%;
                border: none;

                font-family: Inter, sans-serif;
                font-size: 10px;
                font-weight: 400;
                line-height: 9px;
                color: $color-white;

                &:focus {
                    outline: none;
                }
            }

            .posWidgetInputAvgAmount {
                margin-left: auto;
                font-family: Inter, sans-serif;
                font-size: 10px;
                font-weight: 300;
                line-height: 9px;
                color: rgba(160, 160, 196, 1);
            }
        }
    }
}

.colTitle {
    position: relative;

    &_active {
        color: rgba(137, 156, 255, 1);

        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
    }

    .colHint {
        color: rgba(106, 106, 145, 1);

        position: absolute;
        top: calc(100% + 12px);
        left: -12px;
        z-index: -100;

        padding: 12px;
        width: 240px;

        background: rgba(7, 8, 19, 1);
        border-radius: 12px;
        border: 1px solid rgba(43, 53, 108, 1);

        display: flex;
        flex-direction: column;
        gap: 12px;

        text-wrap: wrap;
        text-align: center;
        line-height: 11px;

        opacity: 0;
        transform: translateY(10px);

        transition:
            opacity 0.3s ease,
            transform 0.3s ease;

        &_visible {
            opacity: 1;
            z-index: 100;
            transform: translateY(0);
        }

        .hintHeader {
            position: relative;
            display: flex;
            align-items: center;
            gap: 6px;

            .hintTitle {
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 400;
                line-height: 9px;

                color: $color-white;
            }

            .hintHighlight {
                position: absolute;
                top: -23px;
                left: 6px;
                transform: rotate(90deg);
            }
        }

        .hintText {
            font-family: Inter, sans-serif;
            font-size: 10.26px;
            font-weight: 400;
            line-height: 12.72px;
            text-align: left;
            color: rgba(106, 106, 145, 1);

            .hintSubtitle {
                font-weight: 500;
                color: rgba(158, 158, 184, 1);
            }
        }
    }
}
