@import '../../../styles/vars';
@import '../../../styles/mixins';

.loadingPopup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $color-blue-bold;
    border-radius: 10px;
    padding: 20px;
    z-index: 9999;
}
