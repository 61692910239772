@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.section {
    padding: 16px;

    background: rgba(9, 9, 14, 1);

    border-radius: 16px;

    display: grid;
    grid-template-columns: 55% 43%;

    position: relative;

    @include gradient-border(
        16px,
        1px,
        radial-gradient(95.12% 137.84% at 50.64% 0%, #383c54 0%, #13141d 100%),
        null
    );

    transition: background 0.4s ease-in;

    &_active {
        &:hover {
            @include gradient-border(
                16px,
                1px,
                linear-gradient(
                    0deg,
                    rgba(88, 113, 242, 0.68),
                    rgba(88, 113, 242, 0.68)
                ),
                radial-gradient(
                    95.12% 137.84% at 50.64% 0%,
                    #383c54 0%,
                    #13141d 100%
                )
            );
        }
    }

    &_focused {
        @include fadeBg(
            fadeInBg,
            rgba(9, 9, 14, 1),
            radial-gradient(
                23.87% 83.26% at 0% 66.89%,
                rgba(137, 156, 255, 0.26) 0%,
                rgba(21, 21, 30, 0) 100%
            ),
            0.4s,
            ease-out,
            linear-gradient(0deg, #09090e, #09090e)
        );

        animation: fadeInBg 0.4s ease-out;

        background: radial-gradient(
                23.87% 83.26% at 0% 66.89%,
                rgba(137, 156, 255, 0.26) 0%,
                rgba(21, 21, 30, 0) 100%
            ),
            linear-gradient(0deg, #09090e, #09090e);
    }

    .price {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;

        .amount {
            width: 100%;

            font-family: Inter, sans-serif;
            font-size: 22px;
            font-weight: 600;
            line-height: 21px;
            color: $color-white;

            &:focus {
                outline: none;
            }

            &_inactive {
                background-color: transparent;
                border-color: transparent;
                pointer-events: none;
            }
        }

        .amountUsdt {
            margin-left: 12px;
            @include setFont(12px, 500, $color-grey);
        }

        .title {
            font-family: Inter, sans-serif;
            font-size: 10px;
            font-weight: 300;
            line-height: 12.4px;
            color: rgba(160, 160, 196, 1);
            padding: 4px 0;
        }
    }

    .inputOptions {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: flex-end;
        gap: 8px;

        .inputButtons {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            gap: 12px;

            .inputWalletCount {
                display: flex;
                align-items: center;
                gap: 4px;

                .inputWalletImgContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    background: rgba(255, 255, 255, 0.05);
                    border-radius: 4px;
                }

                .inputWalletCountText {
                    color: rgba(137, 156, 255, 1);
                    font-family: Inter, sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 12px;
                    text-wrap: nowrap;
                }
            }

            .btnsContainer {
                display: flex;
                gap: 4px;

                .walletBtn {
                    cursor: pointer;
                    width: 100%;
                    padding: 4px 6px;
                    background: rgba(137, 156, 255, 0.14);
                    border: none;
                    border-radius: 4px;

                    color: rgba(137, 156, 255, 1);
                    font-family: Inter, sans-serif;
                    font-size: 8px;
                    font-weight: 400;
                    line-height: 8px;

                    &:hover {
                        filter: brightness(140%);
                    }
                }
            }
        }
    }

    .tokenDataBox {
        display: flex;
        gap: 4px;
        align-items: center;

        .tokenUsdPrice {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 300;
            line-height: 12px;
            text-align: right;
            color: rgba(160, 160, 196, 1);
            text-wrap: nowrap;
        }
    }

    .tokenWrapper {
        background: rgba(137, 156, 255, 0.14);
        border: none;
        padding: 6px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 4px;

        &_active {
            cursor: pointer;

            &:hover {
                background: rgba(137, 156, 255, 0.27);
            }
        }

        .tokenIcon {
            width: 12px;
            height: 12px;
            border-radius: 4px;
        }

        .tokenName {
            font-family: Inter, sans-serif;
            font-size: 10px;
            font-weight: 400;
            line-height: 9px;
            color: $color-white;
            text-wrap: nowrap;
        }
    }
}

.inputHighlight {
    position: absolute;
    opacity: 0;
    pointer-events: none;
    left: 1px;
    bottom: 20%;
    height: 35%;
    width: 4px;

    @include fade(fadeIn, 0, 1, 0.2s, linear);
    @include fade(fadeOut, 1, 0, 0.2s, linear);

    animation: fadeOut 0.4s linear;

    &_visible {
        opacity: 1;
        animation: fadeIn 0.4s linear;
    }
}
