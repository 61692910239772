@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.item {
    padding-bottom: 5px;
}

.input {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.sideBarLinks__linebtm {
    width: 100%;
    height: 0.5px;
    border: 0.5px solid;
    border-image-source: radial-gradient(50% 7100% at 50% 0%, rgba(159, 167, 222, 0.75) 0%, rgba(0, 0, 0, 0.068) 100%);
    border-image-slice: 1;
    margin: 20px 0;
    position: relative;
    display: block;
}

.title {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    margin-bottom: 16px;
    @include flexCol();
    gap: 5px;
    text-align: center;
    width: 100%;

    @include smScreenMedia {
        text-align: center;
    }

    & svg {
        @include svg(18px, $color-white, 12px);
    }

    &__withSubTitle {
        margin-bottom: 5px;
    }
}

.inputLabelText {
    display: block;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 500;
    color:#A0A0C4;

    margin-bottom: 15px;

    text-align: center;
}

.inputContainer {
    position: relative;
    display: flex;
    align-items: center;

    &:focus-within::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 6px;
        height: 28px;
        background: url("data:image/svg+xml,%3Csvg width='6' height='28' viewBox='0 0 6 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_f_2696_26481)'%3E%3Crect x='-1.5' y='3.7168' width='3.74165' height='20.5666' rx='1.87083' fill='%23899CFF'/%3E%3C/g%3E%3Crect x='-1.5' y='3.7168' width='3.74165' height='20.5666' rx='1.87083' fill='%23899CFF'/%3E%3Cdefs%3E%3Cfilter id='filter0_f_2696_26481' x='-4.70713' y='0.509665' width='10.156' height='26.9809' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='BackgroundImageFix' result='shape'/%3E%3CfeGaussianBlur stdDeviation='1.60357' result='effect1_foregroundBlur_2696_26481'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E") no-repeat center;
    }
}

.timeInfo {
    position: absolute;
    right: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
    pointer-events: none;
}

.timeText {
    color: #A0A0C4;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 14px;
}
