@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.sideBar__backdrop {
    display: none;

    @include mdScreenMedia {
        
        pointer-events: none;
        
        .sideBar:not(.compact) & {
            pointer-events: auto;
        }
    }
}

.sideBar {
    width: 100%;
    max-width: 249px;
    background: linear-gradient(177.43deg, rgba(44, 50, 82, 0.47) 0.8%, rgba(10, 10, 13, 0.47) 99.2%),
    radial-gradient(83.31% 12.83% at 50% 0%, rgba(127, 147, 255, 0.36) 0%, rgba(21, 21, 30, 0) 100%),
    radial-gradient(73.97% 7.66% at 52.59% 101.42%, rgba(127, 147, 255, 0.13) 0%, rgba(21, 21, 30, 0) 100%),
    radial-gradient(67.54% 2.66% at 50% 100%, rgba(137, 156, 255, 0.31) 0%, rgba(0, 0, 0, 0) 100%);
    background-color: #000000;
    transition: width 2550ms ease-out;
    will-change: width;
    transform: translateZ(0);
    position: fixed;
    left: 0;
    overflow: visible;
    top: 0;
    height: 100%;
    border-right: 1px solid;
    border-image-source: radial-gradient(221.41% 89.11% at 100% 19.86%, #383C54 0%, #060610 100%);
    border-image-slice: 1;
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    z-index: 1001;
    transform-origin: left;
    will-change: width;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform, width;
   
    &.compact {
        width: 80px;
        transition: all 650ms ease-out;
    }

    &__closeBtn {
        position: absolute;
        right: -15px;
        top: 30px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0;
        z-index: 1001;
        transition: transform 150ms ease-out;
    }

    &__closeIcon {
        width: 32px;
        height: 32px;
    }
}