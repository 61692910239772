.container {
    display: flex;
    flex-direction: column;
    gap: 42px;
    height: 100vh;
    width: 100%;
    position: relative;
    padding: 0 51.2px;

    .widgetsContainer {
        display: grid;
        grid-template-columns: 0 27%;
        grid-template-rows: 1fr;
        justify-content: center;
        align-items: center;
        transition: grid-template-columns 0.3s ease;

        height: 100%;
        width: 100%;

        padding-bottom: 20px;

        &_expandedPanel {
            gap: 20px;
            grid-template-columns: 1fr 25.7%;
            align-items: start;
        }

        .infoWidgetsContainer {
            display: grid;

            &_expanded {
                gap: 24px;
                grid-template-rows: auto 40%;
            }
        }
    }
}
