.progressLine {
    position: absolute;
    left: 16px;
    top: 0;
    margin-top: 8px;
    height: 100%;
    line {
        transition: all 0.3s ease;
    }
    
    &__base {
        stroke: #191C2B;
        stroke-width: 1;
        transition: all 0.3s ease;
    }
    
    &__active {
        stroke: url(#activeLineGradient);
        stroke-width: 1;
        stroke-linecap: round;
        transition: all 0.3s ease;
    }
}
