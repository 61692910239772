@import '../../../../../../styles/vars';
@import '../../../../../../styles/mixins';

.checkboxesList {
    @include flexCol(flex-start);
    gap: 6px;
    flex-wrap: wrap;
}

.checkbox__input {
    visibility: hidden;
    width: 0;

    &:checked + label {
        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='11' height='9' viewBox='0 0 11 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.6665 4.99935L3.88873 7.22157L9.44428 1.66602' stroke='%23BAC4F7' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
            background-color: #0A102D;
            background-position: center;
            background-repeat: no-repeat;
            border: 0.56px solid #415CE9;
        }
    }
}

.checkbox__label {
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    width: 94px;
    height: 32px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.13), rgba(255, 255, 255, 0.13)),
                linear-gradient(0deg, #273166, #273166),
                radial-gradient(63.23% 295.45% at 0% 50%, rgba(137, 156, 255, 0.4) 0%, rgba(22, 28, 59, 0) 100%);
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:before {
        content: '';
        width: 20px;
        height: 20px;
        position: absolute;
        background: #0A102D;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        border: 0.56px solid #415CE9;
        border-radius: 4.44px;
        background-repeat: no-repeat;
        background-position: center;
    }
}
