@import '../../../../../../styles/vars';
@import '../../../../../../styles/mixins';

.selectContainer {
    position: relative;

    .customSelect {
        width: 100%;
        border: 1px solid $color-white;
        border-radius: 5px;
        background-color: transparent;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top: 22px;
            right: 10px;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-top: 10px solid $color-white;
            transform: translateY(-50%);
            transition: transform 0.3s ease-in-out;
            pointer-events: none;
        }

        &_opened {
            border-color: $color-main;
            background-color: $color-blue-passive;

            &::after {
                border-top: 10px solid $color-main;
                transform: translateY(-50%) rotate(180deg);
            }
        }

        &_selected {
            padding: 12px;
            color: $color-white;
        }

        .selectItems {
            display: hidden;

            &_opened {
                border-radius: 5px;
                background-color: $color-blue-bold;
                z-index: 99;
                width: 100%;
                max-height: 200px;
                overflow-y: auto;
                display: block;
            }

            .selectOption {
                padding: 10px;
                color: $color-white;
                transition: background-color 0.3s;

                &:hover {
                    background-color: $color-main;
                }
            }
        }
    }
}
