@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.widgetHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
        padding: 12px 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;

        text-decoration-line: underline;
        text-decoration-style: solid;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;

        color: rgba(137, 156, 255, 1);
    }

    .headerBtns {
        display: flex;
        align-items: center;
        gap: 20px;

        .headerBtn {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2px;
            border: none;
            background: transparent;
        }
    }
}
