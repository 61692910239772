@import '../../../../../styles/vars';
@import '../../../../../styles/mixins';

.balanceItem {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include smScreenMedia {
        gap: 18px;
    }
    

    &__note {
        font-size: 12px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        color: #A0A0C4;
    }

    &__noteImportant {
        color: #FF6B6B;
    }

    .withdrawBtn {
        width: 579.5px;
        height: 52px;
        padding: 0 16px;
        gap: 6px;
        border-radius: 12px 0 0 0;
        background: #5871F2;
        border: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        margin-top: 20px;
        
        svg {
            margin-right: 6px;
        }
    }

    &__balance {
        background-color:#131832;
        border-radius: 12px;
        padding: 12px;
        @include setFont(16px, 400);
        border: 1px solid #5871F26B;
        outline: none;
        width: 100%;

        display: flex;
        gap: 16px;
        align-items: center;

        & > p {
            padding: 6px 0 4px;
        }

        @include smScreenMedia {
            flex-direction: column;
            gap: 8px;
        }
    }

    &__balanceInfo {
        display: flex;
        gap: 16px;
        align-items: center;
        width: 50%;
        font-family: 'Gilroy', sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #fff;

        @include smScreenMedia {
            width: 100%;
        }
    }

    &__contractBtns {
        display: flex;
        gap: 16px;
        align-items: center;
        margin-left: auto;
    
        @include smScreenMedia {
            width: 100%;
            flex-direction: column;
        }
    }

    &__contractBtn {
        @include setFont(16px, 500);
    }

    &__listItem {
        width: 100%;
        display: flex;
        gap: 25px;

        @include smScreenMedia {
            flex-direction: column;
            gap: 18px;
        }
    }

    &__walletIcon {
        @include svg(24px, transparent, 24px, $color-white);
    }

    &__chainIcon {
        @include svg(24px, $color-white, 24px);
    }

    &__saveBtn {
        @include setFont(16px, 500);
        width: 50%;

        @include smScreenMedia {
            width: 100%;
        }
    }
}
