@import '../../../styles/vars';

.wrapper {
    width: 100%;
    height: 100%;
    background: radial-gradient(
        50.03% 52.48% at 50.03% 47.52%,
        rgba(17, 17, 17, 0.6232) 0%,
        rgba(0, 0, 0, 0.82) 100%
    );

    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    .portfolioContainer {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 20%;
        height: calc(100% - 24px);
        background: linear-gradient(
            177.43deg,
            rgb(20, 22, 34) 0.8%,
            rgba(10, 10, 13, 1) 99.2%
        );
        border: 1px solid rgba(56, 60, 84, 1);
        border-radius: 16px;
        padding: 24px;

        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.portfolioChainsList {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.chainBtn {
    width: 32%;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background: rgba(19, 20, 31, 1);
    border-radius: 6px;

    &_active {
        border: 1px solid rgba(37, 218, 178, 1);
        box-shadow: 0px 4px 9.4px -1px rgba(37, 218, 178, 0.25);
    }
}

.header {
    display: grid;
    grid-template-columns: 48% 48%;
    justify-content: space-between;

    .headerInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .worth {
            font-family: Rubik, sans-serif;
            font-size: 24px;
            font-weight: 500;
            line-height: 26.64px;
            color: $color-white;
        }

        .withdrawBtn {
            width: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;
            padding: 6px 0;

            font-family: Inter, sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 8px;
            letter-spacing: 0.07em;
            color: rgba(196, 196, 217, 1);

            background: rgba(137, 156, 255, 0.14);
            border: none;
            border-radius: 6px;
        }
    }

    .walletsList {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .walletsListItem {
            display: grid;
            align-items: center;
            grid-template-columns: 16px auto 16px 16px;
            gap: 4px;

            .actionBtn {
                display: flex;
                align-items: center;
                justify-content: center;
                border: none;
                width: 16px;
                height: 16px;
                background: rgba(137, 156, 255, 0.14);
                border-radius: 4px;

                & > img {
                    width: 9px;
                    height: 9px;
                }
            }

            .walletAddress {
                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 12px;
                color: rgba(137, 156, 255, 1);
            }

            .chainImgContainer {
                width: 16px;
                height: 16px;
                padding: 4px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 1);
                display: flex;
                align-items: center;
                justify-content: center;

                & > img {
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }
}

.chainPositionsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chainsPositionsData {
        display: flex;
        align-items: center;
        gap: 12px;

        .chainPositionsName {
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 12px;
            color: rgba(160, 160, 196, 1);
        }

        .chainPositionsCount {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 12px;
            background: rgba(88, 113, 242, 1);
            border-radius: 8px;

            font-family: Inter, sans-serif;
            font-size: 9px;
            font-weight: 400;
            line-height: 9px;
            color: $color-white;
        }
    }

    .chainsPositionsWorth {
        font-family: Rubik, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 17.76px;
        color: $color-white;
    }
}

.posList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;

    .posItem {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 12px;

        .posPriceUsdt {
            margin-left: auto;

            font-family: Rubik, sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 13px;
            color: rgba(160, 160, 196, 1);
        }

        .posData {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 100%;

            .posName {
                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 500;
                line-height: 11px;
                color: $color-white;
            }

            .posPriceNative {
                font-family: Inter;
                font-size: 10px;
                font-weight: 400;
                line-height: 9px;
                letter-spacing: 0.07em;

                color: rgba(160, 160, 196, 1);
            }
        }

        .posImgContainer {
            flex-shrink: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            width: 36px;
            height: 36px;
            background: rgba(0, 0, 0, 1);

            & > img {
                width: 50%;
                height: 50%;
                border-radius: 4px;
            }
        }
    }
}
