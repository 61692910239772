@import '../../../../styles/vars';

.customWalletMenu {
    position: relative;
    display: flex;
    justify-content: space-between;
    background: rgba(9, 9, 14, 1);
    border: 1px solid rgba(56, 60, 84, 1);
    padding: 16px 16px 16px 4px;
    border-radius: 16px;
    align-items: center;

    .walletsList {
        position: absolute;
        right: 0;
        bottom: calc(100% + 16px);
        background: rgba(7, 8, 19, 1);
        border: 1px solid rgba(43, 53, 108, 1);
        border-radius: 10px;
        overflow: hidden;

        display: flex;
        flex-direction: column;

        .walletsListItem {
            display: flex;
            flex-direction: column;
            gap: 6px;
            background: inherit;
            border: none;
            padding: 12px;

            &:hover {
                position: relative;
                &:hover {
                    background: linear-gradient(
                            90deg,
                            rgba(137, 156, 255, 0.12) 0%,
                            rgba(49, 81, 251, 0.02) 100%
                        ),
                        radial-gradient(
                            32.25% 112.5% at 0% 50%,
                            rgba(137, 156, 255, 0.101333) 0%,
                            rgba(21, 21, 30, 0) 100%
                        );

                    &::before {
                        position: absolute;
                        content: '';
                        background-image: url('../../../../assets/icons/sniper/input-highlight.png');
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        width: 4px;
                        height: 50%;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }

            .walletItemName {
                display: flex;
                gap: 4px;
                align-items: center;

                font-family: Inter, sans-serif;
                font-size: 13.68px;
                font-weight: 400;
                line-height: 13.68px;
                color: $color-white;
            }

            .walletItemAddress {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                font-family: Inter, sans-serif;
                font-size: 10.26px;
                font-weight: 400;
                line-height: 10.26px;
                color: rgba(106, 106, 145, 1);
            }
        }
    }

    .customWalletSelectBtn {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 6px;
        gap: 4px;
        justify-content: center;
        background: rgba(27, 30, 48, 1);
        border: none;
        border-radius: 8px;
        width: 30%;

        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 300;
        line-height: 12px;
        color: $color-white;
        text-wrap: nowrap;
    }
}

.walletItemImgContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.05);
    border: none;

    & > img {
        width: 16px;
        height: 16px;
    }
}
