@import '../../../styles/vars';

.notifyContainer {
    position: absolute;
    bottom: 24px;
    left: 24px;
    padding: 16px;

    background-blend-mode: overlay;
    border-radius: 16px;
    width: 16%;
    color: $color-white;

    display: flex;
    flex-direction: column;
    gap: 16px;

    &_success {
        background: linear-gradient(0deg, #070813, #070813),
            radial-gradient(
                15.29% 21.87% at 0.08% 0.7%,
                rgba(105, 231, 134, 0.24) 0%,
                rgba(21, 21, 30, 0) 100%
            );
    }

    &_error {
        background: linear-gradient(0deg, #070813, #070813),
            radial-gradient(
                15.29% 21.87% at 0.08% 0.7%,
                rgba(233, 43, 100, 0.24) 0%,
                rgba(21, 21, 30, 0) 100%
            );
    }

    .notifyTxLink {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        height: 36px;
        border-radius: 16px;

        font-family: Inter, sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 17.28px;
        letter-spacing: 0.07em;
        text-decoration: none;

        color: $color-white;

        background: rgba(88, 113, 242, 0.08);

        &:hover {
            background: rgba(88, 114, 242, 0.25);
        }
    }

    .notifyDescription {
        font-family: Inter, sans-serif;
        font-size: 10.26px;
        font-weight: 400;
        line-height: 12.72px;

        color: rgba(106, 106, 145, 1);
    }

    .notifyHeader {
        display: flex;
        align-items: center;
        gap: 16px;

        .imgContainer {
            flex-shrink: 0;
            border-radius: 10.5px;
            height: 42px;
            width: 42px;
            display: flex;
            align-items: center;
            justify-content: center;

            &_success {
                background: rgba(105, 231, 134, 0.18);
            }

            &_error {
                background: rgba(205, 21, 76, 0.18);
            }
        }

        .notifyTitle {
            font-family: Inter, sans-serif;
            font-size: 20px;
            font-weight: 400;
            line-height: 19px;

            &_success {
                color: rgba(105, 231, 134, 1);
            }

            &_error {
                color: rgba(233, 43, 100, 1);
            }
        }
    }
}
