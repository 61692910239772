@import '../../../styles/vars';

.wrapper {
    width: 100%;
    height: 100%;
    background: radial-gradient(
        50.03% 52.48% at 50.03% 47.52%,
        rgba(17, 17, 17, 0.6232) 0%,
        rgba(0, 0, 0, 0.82) 100%
    );

    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.menuContainer {
    position: relative;
    z-index: 100;
    background: #09090e;
    background: linear-gradient(
            177.43deg,
            rgba(44, 50, 82, 0.24) 0.8%,
            rgba(10, 10, 13, 0.24) 99.2%
        ),
        #09090e;
    width: 46%;
    border-radius: 16px;
    padding: 24px;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;

    .title {
        font-family: Inter, sans-serif;
        font-size: 36px;
        font-weight: 600;
        line-height: 44.64px;
        color: $color-white;
    }

    .closeBtn {
        width: 100%;
        background: rgba(88, 113, 242, 0.08);
        border: 1px solid rgba(88, 113, 242, 0.42);
        border-radius: 16px;
        padding: 16px;
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.04px;
        letter-spacing: 0.07em;
        color: $color-white;
    }
}

.walletsList {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    padding: 16px;

    background: linear-gradient(0deg, #080915, #080915),
        linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.13),
            rgba(255, 255, 255, 0.13)
        );
    border: 1px solid rgba(137, 156, 255, 0.32);
    border-radius: 8px;

    .dividerContainer {
        width: 100%;

        padding: 1px;
        display: flex;
        align-items: center;
        justify-content: center;

        .divider {
            width: 100%;
            height: 1px;
        }
    }

    .walletItem {
        display: flex;
        justify-content: space-between;

        .walletSaveBtn {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            background: rgba(88, 113, 242, 1);
            height: 100%;
            border: none;
            padding: 0 14px;

            font-family: Inter, sans-serif;
            font-size: 12px;
            font-weight: 300;
            line-height: 14.52px;
            color: $color-white;
        }

        .walletInput {
            background: rgba(19, 24, 50, 1);
            border-radius: 12px;
            border: 1px solid rgba(88, 113, 242, 0.42);

            &_name {
                width: 25%;
            }

            &_address {
                width: 63%;
            }

            .walletInputIconWrapper {
                padding: 0;
                margin-bottom: 2px;

                .walletInputIcon {
                    width: 14px;
                    height: 14px;
                }
            }
        }

        .walletName {
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.36px;

            color: $color-white;
        }

        .walletInfo {
            display: flex;
            gap: 24px;
            align-items: center;

            .walletAddress {
                gap: 12px;
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .walletAddressText {
                    font-family: Inter, sans-serif;
                    font-size: 12px;
                    font-weight: 300;
                    line-height: 14.52px;

                    width: 54px;
                    color: $color-white;
                }
            }

            .walletBtns {
                display: flex;
                gap: 12px;
                align-items: center;

                .walletBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 4px;
                    border-radius: 4px;
                    border: none;

                    &_edit {
                        background: rgba(255, 255, 255, 0.12);
                    }

                    &_remove {
                        background: rgba(231, 89, 89, 1);
                    }
                }
            }
        }
    }
}

.addWalletForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 32px;

    .addWalletFormBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        border-radius: 16px;

        font-family: Inter, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 23.04px;
        letter-spacing: 0.07em;
        color: $color-white;
        background: rgba(88, 113, 242, 1);
        border: 1px solid transparent;
    }

    .addWalletInputsContainer {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 32px;

        .addWalletFormRow {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 32px;
        }

        .addWalletInputItem {
            display: flex;
            flex-direction: column;
            gap: 16px;

            &_select {
                position: relative;

                .selectIcon {
                    position: absolute;
                    right: 12px;
                    bottom: 18px;
                }
            }

            .disabledInput {
                &:focus {
                    outline: none;
                }
            }

            .addWalletInputHeader {
                font-family: Inter, sans-serif;
                font-size: 12px;
                font-weight: 400;
                line-height: 14.88px;

                color: $color-white;
            }

            .addWalletInput {
                background: rgba(19, 24, 50, 1);
                border-radius: 12px;
                padding: 12px;
                border: 1px solid rgba(88, 113, 242, 0.42);

                &_active {
                    background: radial-gradient(
                            14.11% 89.93% at 0% 50%,
                            rgba(137, 156, 255, 0.26) 0%,
                            rgba(21, 21, 30, 0) 100%
                        ),
                        #131832;
                }
            }
        }
    }
}

.notify {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 26px;
    gap: 12px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2vh;
    border-radius: 10.5px;

    font-family: Inter, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;

    &_success {
        background: rgba(105, 231, 134, 0.12);
        border: 1px solid rgba(105, 231, 134, 0.42);
        color: rgba(105, 231, 134, 1);
    }

    &_error {
        background: rgba(242, 117, 117, 0.12);
        border: 1px solid rgba(242, 117, 117, 0.42);
        color: rgba(242, 117, 117, 1);
    }
}

.walletsChainsList {
    width: 100%;
    justify-content: space-between;
}

.chainBtn {
    width: 32%;
    padding: 12px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    background: rgba(19, 20, 31, 1);
    border-radius: 6px;
}

.addWalletSelect {
    position: absolute;
    left: 0;
    top: calc(100% + 16px);
    z-index: 100;
    width: 100%;

    display: flex;
    flex-direction: column;
    border-radius: 12px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.65);
    overflow: hidden;

    background: rgba(7, 8, 19, 1);

    .addWalletSelectItem {
        padding: 12px;
        background: inherit;
        border: none;
        position: relative;

        display: flex;
        align-items: center;
        gap: 12px;

        &:hover {
            background: linear-gradient(
                    90deg,
                    rgba(137, 156, 255, 0.12) 0%,
                    rgba(49, 81, 251, 0.02) 100%
                ),
                radial-gradient(
                    32.25% 112.5% at 0% 50%,
                    rgba(137, 156, 255, 0.101333) 0%,
                    rgba(21, 21, 30, 0) 100%
                );

            &::before {
                position: absolute;
                content: '';
                background-image: url('../../../assets/icons/sniper/input-highlight.png');
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 4px;
                height: 50%;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .selectItemImgContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            background: rgba(51, 57, 92, 1);
            border-radius: 10px;
        }

        .selectItemName {
            color: $color-white;
            font-family: Inter, sans-serif;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.36px;
        }
    }
}
