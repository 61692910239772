@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.tokenBox {
    display: flex;
    flex-direction: column;

    .sideBtn {
        cursor: pointer;
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        border: none;
        background: transparent;
        width: 34px;
        height: 28px;
        margin-top: -1px;

        &:hover {
            filter: brightness(110%);
        }

        .switchSideBgIcon {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}
