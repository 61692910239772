@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.balanceBlock {
    display: flex;
    flex-direction: column;
    gap: 25px;

    @include smScreenMedia {
        gap: 18px;
    }

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;

        @include smScreenMedia {
            gap: 18px;
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .borderLine {
    border-top: 1px solid #323232;
    width: 100%;
}

    .withdrawBtn {
    width: 100%;
    height: 52px;
    padding: 0 16px;
    gap: 6px;
    border-radius: 12px;
    background: #5871F2;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
}

    &__list {
        display: flex;
        flex-direction: column;
        gap: 10.7px;
        width: 100%;

        @include smScreenMedia {
            gap: 32px;
        }
    }

    &__listItem {
        width: 100%;
        display: flex;
        gap: 25px;

        @include smScreenMedia {
            flex-direction: column;
            gap: 18px;
        }
    }

    &__walletIcon {
        @include svg(18px, transparent, 18px, $color-white);
    }

    &__chainIcon {
        @include svg(24px, $color-white, 24px);
    }
}

.tooltip {
    margin-left: auto;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltipText {
    visibility: hidden;
    width: 240px;
    background-color: rgba(0, 0, 0, 0.75);
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: -120px;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

.infoIcon {
    margin-left: auto;

    @include svg(24px, $color-white, 24px, $color-white);
}

.copyTooltip {
    position: fixed;
    text-align: center;
    width: 30%;
    top: 120px;
    left: 65%;
    transform: translateX(-65%);
    background-color: $color-blue-active;
    color: white;
    padding: 16px;
    border-radius: 5px;
    font-size: 18px;
    z-index: 100;

    @include smScreenMedia {
        width: 100%;
        left: 50%;
        transform: translateX(-50%);
    }
}
