@import '../../../../styles/vars';
@import '../../../../styles/mixins';

.CEXOutflowsList {
    padding-top: 20px;

    &__container {
        padding: 30px;
        display: grid;
        border-radius: 16px;
        gap: 16px;
        align-items: stretch;

        @include mdScreenMedia {
            background-color: transparent;
        }

        @include smScreenMedia {
            gap: 15px;
            padding: 0 0 20px;
            background-color: transparent;
        }
    }
}
