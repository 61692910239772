@import '../../../styles/vars';
@import '../../../styles/mixins';

.mainBtn {
    width: 100%;
    height: 52px;
    cursor: pointer;
    border: 1px solid #5871F2;
    background: #5871F21F;
    white-space: nowrap;
    text-align: center;
    padding: 15px 10px;
    border-radius: 16px;
    transition: $transition;
    font-size: 14px;
    color: #fff;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;

    @include smScreenMedia {
        font-size: 12px;
    }

    &:hover {
        background: $color-main; // При наведении становятся активными
    }

    &:disabled {
        background: $color-grey;
        cursor: default;
    }

    &__icon {
        display: inline-flex;
        align-items: center;
        margin-right: 8px;
        vertical-align: middle;
    }
}
