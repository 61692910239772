.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(9, 9, 14, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  width: 684px;
  padding: 24px 0 0;
  border-radius: 16px;
  background: linear-gradient(0deg, #09090E, #09090E),
              linear-gradient(177.43deg, rgba(44, 50, 82, 0.24) 0.8%, rgba(10, 10, 13, 0.24) 99.2%);
  border: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-weight: 600;
  line-height: 44.64px;
  text-align: center;
  color: white;
  margin-bottom: 16px;
}

.description {
  color: #A0A0C4;
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  text-align: center;
  margin-bottom: 32px;
  padding: 0 60px;
}

.buttonContainer {
  display: flex;
  gap: 16px;
  padding: 24px;
  width: 100%;
}

.cancelButton {
  width: 321px;
  height: 52px;
  padding: 0 16px;
  border-radius: 16px;
  background: rgba(88, 113, 242, 0.08);
  border: 1px solid rgba(88, 113, 242, 0.42);
  color: white;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.deleteButton {
  width: 299px;
  height: 52px;
  padding: 0 16px;
  border-radius: 16px;
  background: #F25858;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: white;
  cursor: pointer;
}
